export enum ModuleSystem {
  Calculator = 'Calculator',
  ProductReference = 'ProductReference',
  HandOver = 'HandOver',
  ConfirmFinancing = 'ConfirmFinancing',
  Application = 'Application',
  RetryMobile = 'RetryMobile',
  FixPhoneNumber = 'FixPhoneNumber',
  MobileOTP = 'MobileOTP',
  MobileOTPError = 'MobileOTPError',
  Jumio = 'Jumio',
  Summary = 'Summary',
  ContractOTP = 'ContractOTP',
  ContractOTPError = 'ContractOTPError',
  FixName = 'FixName',
  Confirmation = 'Confirmation',
  Rejection = 'Rejection',
  Error = 'Error',
  CatchAllSystem = 'CatchAllSystem',
}

export enum ModuleSystemGroup {
  NoNavigation = 'NoNavigation',
  Basket = 'Basket',
  Financing = 'Financing',
  Application = 'Application',
  Verification = 'Verification',
  Confirmation = 'Confirmation',
  Summary = 'Summary',
}
