import { ZERO_COUNTRY_CODE_OPTIONS } from '../../shared/constants'
import {
  ButtonType,
  FinancingObjectFormFields,
  FormFieldType,
  FormStateType,
} from '../../types/form.types'
import { FixPhoneNumberConfig, Module, ModuleConfig } from '../../types/frontend.types'
import { validateMobilePhoneNrInternational } from '../../shared/validations'
import { ModuleSystem } from '../../engines/pos/pos.types'
import { getFinancingObjectFormLens } from '../../shared/helpers.lenses'

const formFields: FinancingObjectFormFields = [
  {
    type: FormFieldType.FormSection,
    fields: [
      {
        type: FormFieldType.MultiInput,
        label: {
          label: 'formFields.phoneNumber.label',
        },
        fields: [
          {
            style: {
              wrapper: {
                width: 320,
                marginRight: 8,
                marginLeft: 0,
              },
              select: {
                width: '100%',
              },
            },
            itemStyle: {
              marginRight: 0,
            },
            options: ZERO_COUNTRY_CODE_OPTIONS,
            required: true,
            type: FormFieldType.TextSelect,
            lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'countryCode']),
          },
          {
            itemStyle: {
              marginLeft: 0,
            },
            name: 'phoneNumber',
            validate: (value, formState) =>
              validateMobilePhoneNrInternational({
                phone: value,
                countryCode: formState.customerInfo.baseInfo.countryCode,
              }),
            required: true,
            isAutoFocused: true,
            type: FormFieldType.TextInput,
            lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'phone']),
          },
        ],
      },
    ],
  },
]

export const FixPhoneNumber = (props: FixPhoneNumberConfig): ModuleConfig => ({
  type: Module.Form,
  moduleHeader: {
    title: 'fixPhoneNumber.fixPhoneNumberTitle',
  },
  formStateType: FormStateType.FinancingObject,
  formProps: {
    onSubmit: ({ formState, patchFinObj }) => {
      props.onSubmit({ finObj: formState, patchFinObj })
    },
    formFields,
    buttons: [
      {
        type: ButtonType.Secondary,
        label: 'navigation.cancel',
        onClick: ({ setModuleSystem }) => {
          if (props.onCancel) {
            props.onCancel({ setModuleSystem })
          } else {
            setModuleSystem(ModuleSystem.MobileOTP)
          }
        },
      },
      {
        type: ButtonType.Primary,
        label: 'fixPhoneNumber.updatePhoneNumberButtonLabel',
        onClick: ({ submit }) => {
          submit()
        },
      },
    ],
    skipAutoFocus: true,
  },
})
