export enum Env {
  Dev = 'dev',
  Test = 'test',
  Stage = 'stage',
  Blue = 'blue',
  Preprod = 'preprod',
  Prod = 'prod',
}

// App config types
export type AppConfig = {
  azure: {
    appInsightsConnectionString: string
    clientId: string
    tenantId: string
  }
  app: {
    whitelabelBobBackendApiUrl: string
    whitelabelConfigApiUrl: string
    whitelabelEnvironment: Env
    whitelabelFinancingApiUrl: string
    whitelabelPartnerOnboardingApiUrl: string
    whitelabelWebAppUrl: string

    dataPrivacyAndDataProtectionUrl: {
      de: string
      en: string
      fr: string
      it: string
    }
  }
  locize: {
    apiKey: string
    productId: string
    version: string
  }
  post: {
    addressAssistant: {
      apiKey: string
      url: string
    }
  }
}
