import { Loading } from 'frr-web/lib/components/Loading'
import { Div, H3, P } from 'frr-web/lib/html'
import React, { useState } from 'react'
import { verifyEmail } from '../../data/api/fetchapi/financing/verifyEmail'
import { useCSSStyles, useInlineStyle, useModuleTheme } from '../../shared/theme/module.theme'
import { useAppConfig } from '../providers/AppConfigData.provider'

export const EmailValidator = (props: {
  emailAddress: string
  emailToken: string
  financingSessionToken: string
  onEmailVerified?: (session: string) => void
  onEmailVerificationFailed?: () => void
}) => {
  const { appConfig } = useAppConfig()

  const theme = useModuleTheme()
  const getCSSStyles = useCSSStyles(theme, 'emailVerification')({})
  const getInlineStyles = useInlineStyle(theme, 'emailVerification')({})

  const [validation, setValidation] = useState({
    isValidated: false,
    isValid: false,
    error: null as null | string,
  })

  React.useEffect(() => {
    verifyEmail({
      appConfig,
      emailToken: props.emailToken,
      financingSessionToken: props.financingSessionToken,
    }).then(({ session, error }) => {
      if (error) {
        if (props.onEmailVerificationFailed) {
          props.onEmailVerificationFailed()
        } else {
          setValidation({ isValidated: true, isValid: false, error })
        }
      } else {
        if (props.onEmailVerified && session) {
          props.onEmailVerified(session)
        } else {
          setValidation({ isValidated: true, isValid: true, error: null })
        }
      }
    })
  }, [])

  if (validation.error) {
    throw validation.error
  }

  const renderLoader = (props: { isLoaderOnly: boolean }) => (
    <Div
      {...getCSSStyles({
        wrapper: true,
        wrapperLoaderOnly: props.isLoaderOnly,
      })}
    >
      <Loading style={{ transform: 'scale(0.5)' }} />
    </Div>
  )

  return props.onEmailVerified ? (
    <Div {...getCSSStyles({ container: true, containerLoaderOnly: true })}>
      {renderLoader({ isLoaderOnly: true })}
    </Div>
  ) : (
    <Div {...getCSSStyles('container')}>
      {validation.isValidated
        ? (validation.isValid && (
            <Div dataTestId='email-verification-success' {...getCSSStyles('wrapper')}>
              <img
                src={'/check-mark.svg'}
                alt='checkmark'
                style={getInlineStyles('checkmarkImage').style}
              />
              <H3 label='emailVerification.success.title' {...getCSSStyles('title')} />
              <P
                label='emailVerification.success.text'
                data={{ email: props.emailAddress }}
                {...getCSSStyles('successText')}
              />
            </Div>
          )) || (
            <Div {...getCSSStyles('wrapper')}>
              <P label='emailVerification.validationFailed' {...getCSSStyles('failureText')} />
            </Div>
          )
        : renderLoader({ isLoaderOnly: false })}
    </Div>
  )
}

