import { ReactNode, useEffect } from 'react'
import { OnboardingChannel, PartnerOnboardingChannel } from '../../types/financing.types'
import { useParams } from 'react-router-dom'
import { useProduct } from '../context/PartnerProduct.context'

import { initI18next } from '../scaffold/translation/initI18next'
import { useAppConfig } from './AppConfigData.provider'
import { useTracker } from '../context/Tracker.context'

const OnboardingChannelNamespaceMap: Partial<Record<string, string>> = {
  [OnboardingChannel.BPL_ECOMMERCE]: 'bob_pay_later',
  [OnboardingChannel.BPL_RETAIL]: 'bob_pay_later',
  [OnboardingChannel.BROKER]: 'credit',
  [OnboardingChannel.CREDIT]: 'credit',
  [OnboardingChannel.ECOMMERCE]: 'pos',
  [OnboardingChannel.PARTNER_RETAIL]: 'credit',
  [OnboardingChannel.POS]: 'pos',
  [OnboardingChannel.POSTFINANCE]: 'credit',
  [OnboardingChannel.RETAIL]: 'retail',
  [PartnerOnboardingChannel.PARTNER_ONBOARDING]: 'partner_onboarding',
}

export const TranslationProvider = () => {
  const { appConfig } = useAppConfig()
  const { product, configuration } = useProduct()
  const { lang } = useParams<{ lang: string }>()
  const { tracker } = useTracker()

  const partnerNamespace = configuration.otherConfig.languageNamespacePartner
  const onboardingChannel =
    (product.isPartnerOnboarding && PartnerOnboardingChannel.PARTNER_ONBOARDING) ||
    product.onboardingChannel ||
    OnboardingChannel.CREDIT
  const onboardingChannelNamespace = OnboardingChannelNamespaceMap[onboardingChannel]

  useEffect(() => {
    if (onboardingChannelNamespace) {
      initI18next({
        appConfig,
        initialLanguage: lang,
        onboardingChannelNamespace,
        partnerNamespace,
        tracker,
      })
    }
  }, [partnerNamespace, onboardingChannelNamespace])

  return null
}
