import { createThemeConfigure, CSSProperties } from 'frr-web/lib/theme/configure.theme'
import { getUseCSSStyles, getUseInlineStyle } from 'frr-web/lib/theme/util'
import * as React from 'react'

export type ModuleTheme = {
  calculator: {
    brokerForm: CSSProperties
    calculatorContent: CSSProperties
    calculatorWrapper: CSSProperties
    containerLeft: CSSProperties
    containerRight: CSSProperties
    containerRightCheckout: CSSProperties
    footerWrapper: CSSProperties
    headerText: CSSProperties
    headerWrapper: CSSProperties
    introduction: CSSProperties
    monthlyRate: CSSProperties
    previewForm: CSSProperties
    previewFormPlaceholder: CSSProperties
    previewInsurance: CSSProperties
    previewInterest: CSSProperties
    previewMonthlyItem: CSSProperties
    previewMonthlyItems: CSSProperties
    previewMonthlyRate: CSSProperties
    wrapper: CSSProperties
  }

  codeVerification: { wrapper: CSSProperties }

  contactSection: {
    contactBox: CSSProperties
    contactBoxesWrapper: CSSProperties
    contactBoxIcon: CSSProperties
    contactBoxText: CSSProperties
    contactLink: CSSProperties
    description: CSSProperties
    title: CSSProperties
    wrapper: CSSProperties
  }

  contract: {
    wrapper: CSSProperties
    title: CSSProperties
    subtitle: CSSProperties
    description: CSSProperties
    buttonWrapper: CSSProperties
    bottomButtonWrapper: CSSProperties
    pdfWrapper: CSSProperties
    documentLinkWrapper: CSSProperties
    documentLink: CSSProperties
  }

  creditIdentityDataRetrievalSelection: {
    contentWrapper: CSSProperties
    description: CSSProperties
    introduction: CSSProperties
    optionButton: CSSProperties
    optionButtonWrapper: CSSProperties
    optionContentWrapper: CSSProperties
    optionDescription: CSSProperties
    optionIcon: CSSProperties
    optionInfoDescription: CSSProperties
    optionInfoItem: CSSProperties
    optionInfoList: CSSProperties
    optionLabel: CSSProperties
    optionLabelPrimary: CSSProperties
    optionWrapper: CSSProperties
    optionWrapperActive: CSSProperties
  }

  creditConfirmation: {
    buttonWrapper: CSSProperties
    description: CSSProperties
    icon: CSSProperties
    iconRow: CSSProperties
    iconRowIcon: CSSProperties
    listItem: CSSProperties
    modalInnerWrapper: CSSProperties
    section: CSSProperties
  }

  customerIDVerification: {
    buttonWrapper: CSSProperties
    description: CSSProperties
    iFrameWrapper: CSSProperties
    iFrameHiddenWrapper: CSSProperties
    innerWrapper: CSSProperties
    optionIcon: CSSProperties
    optionLabel: CSSProperties
    optionsWrapper: CSSProperties
    optionWrapper: CSSProperties
    wrapper: CSSProperties
  }

  customerIntroduction: {
    buttonWrapper: CSSProperties
    description: CSSProperties
    innerWrapper: CSSProperties
    wrapper: CSSProperties
    title: CSSProperties
  }

  emailVerification: {
    container: CSSProperties
    containerLoaderOnly: CSSProperties
    wrapper: CSSProperties
    wrapperLoaderOnly: CSSProperties
    failureText: CSSProperties
    loadingText: CSSProperties
    successText: CSSProperties
    title: CSSProperties
    checkmarkImage: CSSProperties
  }

  generic: {
    bottomButtonWrapper: CSSProperties
    buttonWrapper: CSSProperties
    contactSectionWrapper: CSSProperties
    description: CSSProperties
    descriptionText: CSSProperties
    descriptionWrapper: CSSProperties
    imageWrapper: CSSProperties
    loadingWrapperBelow: CSSProperties
    subtitle: CSSProperties
    title: CSSProperties
    titleMobile: CSSProperties
    wrapper: CSSProperties
  }

  information: {
    buttonWrapper: CSSProperties
    description: CSSProperties
    emailButtonWrapper: CSSProperties
    emailLabel: CSSProperties
    icon: CSSProperties
    innerWrapper: CSSProperties
    outerWrapper: CSSProperties
    resendErrorLabel: CSSProperties
    resendInputWrapper: CSSProperties
    resendLabel: CSSProperties
    resendWrapper: CSSProperties
    wrapper: CSSProperties
  }

  jumio: { iFrame: CSSProperties; emailOptionWrapper: CSSProperties; sendEmailLabel: CSSProperties }

  merchantBanner: {
    outerWrapper: CSSProperties
    innerWrapper: CSSProperties
    label: CSSProperties
    icon: CSSProperties
  }

  paymentTerms: {
    buttonWrapper: CSSProperties
    contentWrapper: CSSProperties
    contentLeftWrapper: CSSProperties
    contentRightWrapper: CSSProperties
    previewForm: CSSProperties
    optionGroup: CSSProperties
    optionItem: CSSProperties
    optionItemActive: CSSProperties
    optionValue: CSSProperties
    optionLabel: CSSProperties
    optionSublabel: CSSProperties
  }

  processing: {
    buttonWrapper: CSSProperties
    description: CSSProperties
    icon: CSSProperties
    innerWrapper: CSSProperties
    list: CSSProperties
    listItem: CSSProperties
    outerWrapper: CSSProperties
    wrapper: CSSProperties
  }

  selection: {
    wrapper: CSSProperties
    title: CSSProperties
    subtitle: CSSProperties
    optionsWrapper: CSSProperties
    optionWrapper: CSSProperties
    optionTitle: CSSProperties
    optionDescription: CSSProperties
    optionIcon: CSSProperties
    optionButton: CSSProperties
  }

  steps: {
    wrapper: CSSProperties
    itemWrapper: CSSProperties
    line: CSSProperties
    lineCompleted: CSSProperties
    iconWrapper: CSSProperties
    iconWrapperActive: CSSProperties
    iconWrapperCompleted: CSSProperties
    iconWrapperWarning: CSSProperties
    iconWrapperRejected: CSSProperties
    icon: CSSProperties
    iconActive: CSSProperties
    iconCompleted: CSSProperties
    iconWarning: CSSProperties
    iconRejected: CSSProperties
    labelWrapper: CSSProperties
    label: CSSProperties
    labelActive: CSSProperties
    labelCompleted: CSSProperties
    labelRejected: CSSProperties
    sublabel: CSSProperties
  }

  waiting: {
    descriptionText: CSSProperties
    descriptionWrapper: CSSProperties
    icon: CSSProperties
    loadingWrapper: CSSProperties
    resendButton: CSSProperties
    resendErrorLabel: CSSProperties
    resendInputWrapper: CSSProperties
    resendLabel: CSSProperties
    resendWrapper: CSSProperties
    titleText: CSSProperties
    wrapper: CSSProperties
  }
}

const defaultModuleTheme: ModuleTheme = {
  calculator: {
    brokerForm: {},
    calculatorContent: {},
    calculatorWrapper: {},
    containerLeft: {},
    containerRight: {},
    containerRightCheckout: {},
    footerWrapper: {},
    headerText: {},
    headerWrapper: {},
    introduction: {},
    monthlyRate: {},
    previewForm: {},
    previewFormPlaceholder: {},
    previewInsurance: {},
    previewInterest: {},
    previewMonthlyItem: {},
    previewMonthlyItems: {},
    previewMonthlyRate: {},
    wrapper: {},
  },
  codeVerification: { wrapper: {} },
  contactSection: {
    contactBox: {},
    contactBoxesWrapper: {},
    contactBoxIcon: {},
    contactBoxText: {},
    contactLink: {},
    description: {},
    title: {},
    wrapper: {},
  },
  contract: {
    wrapper: {},
    title: {},
    subtitle: {},
    description: {},
    buttonWrapper: {},
    bottomButtonWrapper: {},
    pdfWrapper: {},
    documentLinkWrapper: {},
    documentLink: {},
  },
  creditIdentityDataRetrievalSelection: {
    contentWrapper: {},
    description: {},
    introduction: {},
    optionButton: {},
    optionButtonWrapper: {},
    optionContentWrapper: {},
    optionDescription: {},
    optionIcon: {},
    optionInfoDescription: {},
    optionInfoItem: {},
    optionInfoList: {},
    optionLabel: {},
    optionLabelPrimary: {},
    optionWrapper: {},
    optionWrapperActive: {},
  },
  creditConfirmation: {
    buttonWrapper: {},
    description: {},
    icon: {},
    iconRow: {},
    iconRowIcon: {},
    listItem: {},
    modalInnerWrapper: {},
    section: {},
  },
  customerIDVerification: {
    buttonWrapper: {},
    description: {},
    innerWrapper: {},
    iFrameWrapper: {},
    iFrameHiddenWrapper: {},
    optionIcon: {},
    optionLabel: {},
    optionsWrapper: {},
    optionWrapper: {},
    wrapper: {},
  },
  customerIntroduction: { buttonWrapper: {}, description: {}, innerWrapper: {}, title: {}, wrapper: {} },
  emailVerification: {
    container: {},
    containerLoaderOnly: {},
    wrapper: {},
    wrapperLoaderOnly: {},
    failureText: {},
    loadingText: {},
    successText: {},
    title: {},
    checkmarkImage: {},
  },
  generic: {
    bottomButtonWrapper: {},
    buttonWrapper: {},
    contactSectionWrapper: {},
    description: {},
    descriptionText: {},
    descriptionWrapper: {},
    imageWrapper: {},
    loadingWrapperBelow: {},
    subtitle: {},
    title: {},
    titleMobile: {},
    wrapper: {},
  },
  information: {
    buttonWrapper: {},
    description: {},
    emailButtonWrapper: {},
    emailLabel: {},
    icon: {},
    innerWrapper: {},
    outerWrapper: {},
    resendErrorLabel: {},
    resendInputWrapper: {},
    resendLabel: {},
    resendWrapper: {},
    wrapper: {},
  },
  jumio: { iFrame: {}, emailOptionWrapper: {}, sendEmailLabel: {} },
  merchantBanner: { outerWrapper: {}, innerWrapper: {}, label: {}, icon: {} },
  paymentTerms: {
    buttonWrapper: {},
    contentWrapper: {},
    contentLeftWrapper: {},
    contentRightWrapper: {},
    previewForm: {},
    optionItemActive: {},
    optionGroup: {},
    optionItem: {},
    optionValue: {},
    optionLabel: {},
    optionSublabel: {},
  },
  processing: {
    buttonWrapper: {},
    description: {},
    icon: {},
    innerWrapper: {},
    list: {},
    listItem: {},
    outerWrapper: {},
    wrapper: {},
  },
  selection: {
    wrapper: {},
    title: {},
    subtitle: {},
    optionsWrapper: {},
    optionWrapper: {},
    optionTitle: {},
    optionDescription: {},
    optionIcon: {},
    optionButton: {},
  },
  steps: {
    wrapper: {},
    itemWrapper: {},
    line: {},
    lineCompleted: {},
    iconWrapper: {},
    iconWrapperActive: {},
    iconWrapperCompleted: {},
    iconWrapperWarning: {},
    iconWrapperRejected: {},
    icon: {},
    iconActive: {},
    iconCompleted: {},
    iconWarning: {},
    iconRejected: {},
    labelWrapper: {},
    label: {},
    labelActive: {},
    labelCompleted: {},
    labelRejected: {},
    sublabel: {},
  },
  waiting: {
    descriptionText: {},
    descriptionWrapper: {},
    icon: {},
    loadingWrapper: {},
    resendButton: {},
    resendErrorLabel: {},
    resendInputWrapper: {},
    resendLabel: {},
    resendWrapper: {},
    titleText: {},
    wrapper: {},
  },
}

export type ModuleThemeConfig = {
  [k in keyof ModuleTheme]?: Partial<ModuleTheme[k]>
}

export const useModuleTheme = (): ModuleTheme => {
  const theme = React.useContext(ModuleThemeContext)
  if (!theme) {
    throw new Error(`ModuleTheme not found`)
  }
  return theme
}

export const ModuleThemeContext = React.createContext<ModuleTheme>(undefined as unknown as ModuleTheme)

ModuleThemeContext.displayName = 'Module Theme Context'

export const configureModuleTheme = createThemeConfigure<
  any, // TODO
  ModuleTheme
>(defaultModuleTheme)

export const useInlineStyle = getUseInlineStyle<ModuleTheme>()
export const useCSSStyles = getUseCSSStyles<ModuleTheme>()
