import {
  ButtonType,
  FinancingObjectFormFields,
  FormFieldType,
  FormStateType,
} from '../../types/form.types'
import { Module, ModuleConfig } from '../../types/frontend.types'
import { validateEmail } from '../../shared/validations'
import { ModuleSystem } from '../../engines/pos/pos.types'
import { getFinancingObjectFormLens } from '../../shared/helpers.lenses'

const formFields: FinancingObjectFormFields = [
  {
    type: FormFieldType.FormSection,
    fields: [
      {
        type: FormFieldType.TextInput,
        label: {
          label: 'formFields.email.label',
        },
        validate: validateEmail,
        required: true,
        lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'email']),
      },
    ],
  },
]

export const FixEmail = (): ModuleConfig => ({
  type: Module.Form,
  moduleHeader: {
    title: 'fixEmail.fixEmailTitle',
    // description: 'fixEmail.fixEmailDescription',
  },
  formStateType: FormStateType.FinancingObject,
  formProps: {
    onSubmit: ({ patchFinObj, formState }) => {
      patchFinObj({
        updates: [
          {
            value: true,
            path: '/status/emailVerification/isEmailResendAttempted',
            op: 'replace',
          },
          {
            value: formState.customerInfo.baseInfo.email,
            path: '/customer/baseInfo/email',
            op: 'replace',
          },
        ],
      })
    },
    formFields,
    buttons: [
      {
        type: ButtonType.Secondary,
        label: 'navigation.cancel',
        onClick: ({ setModuleSystem }) => {
          setModuleSystem(ModuleSystem.MobileOTP)
        },
      },
      {
        type: ButtonType.Primary,
        label: 'button.updateEmail',
        onClick: ({ submit }) => {
          submit()
        },
      },
    ],
  },
})
