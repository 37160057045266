import { useQuery } from '@tanstack/react-query'
import { createContext, ReactNode, useContext } from 'react'
import { getUserTypeFromSession } from '../../shared/helpers'
import { OnboardingChannel, ProductSettingsT } from '../../types/financing.types'
import { EngineConfig, UserType } from '../../types/frontend.types'
import { useProduct } from './PartnerProduct.context'
import { Loading } from '../scaffold/Loading'
import { NotFound } from '../scaffold/NotFound'

// engines

export const computeEngine = async (
  product: ProductSettingsT,
  type: 'partner' | 'customer' | 'customer-verification',
): Promise<EngineConfig> => {
  if (product.isPartnerOnboarding) {
    return (await import('../../engines/partnerOnboarding/partnerOnboarding.engine')).PartnerOnboardingEngine
  }

  if (
    product.onboardingChannel === OnboardingChannel.BROKER ||
    product.onboardingChannel === OnboardingChannel.CREDIT ||
    product.onboardingChannel === OnboardingChannel.PARTNER_RETAIL ||
    product.onboardingChannel === OnboardingChannel.POSTFINANCE
  ) {
    if (product.isBobInternalFlow) {
      return (await import('../../engines/credit/internal/creditInternal.engine')).CreditInternalEngine
    } else {
      return (await import('../../engines/credit/external/creditExternal.engine')).CreditExternalEngine
    }
  }

  if (product.onboardingChannel === OnboardingChannel.ECOMMERCE) {
    return (await import('../../engines/ecommerce/ecommerce.engine')).EcommerceEngine
  }
  if (product.onboardingChannel === OnboardingChannel.POS) {
    return (await import('../../engines/pos/pos.engine')).PosEngine
  }

  if (product.onboardingChannel === OnboardingChannel.BPL_RETAIL) {
    if (type === UserType.CustomerVerification || type === UserType.Customer) {
      return (await import('../../engines/bpl/customerVerfication/bplCustomerVerification.engine'))
        .BplCustomerVerificationEngine
    } else {
      return (await import('../../engines/bpl/retail/bplRetail.engine')).BplRetailEngine
    }
  }

  if (product.onboardingChannel === OnboardingChannel.BPL_ECOMMERCE) {
    if (type === UserType.CustomerVerification) {
      return (await import('../../engines/bpl/customerVerfication/bplCustomerVerification.engine'))
        .BplCustomerVerificationEngine
    } else {
      return (await import('../../engines/bpl/eCommerce/bplEcommerce.engine')).BplEcommerceEngine
    }
  }

  if (product.onboardingChannel === OnboardingChannel.RETAIL) {
    if (type === 'partner') {
      return (await import('../../engines/retail/partner/retailPartner.engine')).RetailPartnerEngine
    } else {
      return (await import('../../engines/retail/customer/retailCustomer.engine')).RetailCustomerEngine
    }
  }

  throw new Error('no engine found')
}

export const useEngine = (): EngineConfig => {
  const engine = useContext(EngineContext)

  if (!engine) {
    throw new Error('Engine not found')
  }

  return engine
}

export const EngineContext = createContext<EngineConfig | undefined>(undefined)
EngineContext.displayName = 'EngineContext'

export const EngineProvider = (props: { children: ReactNode }) => {
  const { product } = useProduct()

  const { data: engine, isLoading } = useQuery(['engine'], () => computeEngine(product, getUserTypeFromSession()), {
    refetchOnWindowFocus: false,
  })

  if (isLoading) {
    return <Loading label='loading engine' />
  }

  if (!engine) {
    return <NotFound label='Engine not found' />
  }

  return <EngineContext.Provider value={engine}>{props.children}</EngineContext.Provider>
}
