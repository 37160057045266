import { useQuery } from '@tanstack/react-query'
import { defaultPartnerConfig } from '../../engines/default.config'
import {
  OnboardingChannel,
  PartnerOnboardingChannel,
  ProductSettingsT,
} from '../../types/financing.types'
import { BrandTheme, PartnerFrontendConfiguration } from '../../types/frontend.types'
import { PartnerService } from '../api/openapi'
import { QueryKey } from '../api/api.types'
import { AppConfig } from '../../types/app.types'
import { useAppConfig } from '../../view/providers/AppConfigData.provider'

/*
 * Product
 */

export type ProductQueryParams = {
  urlHostname?: string
  productId?: string
  isPartnerOnboarding?: string
}

export const getProductQuery = async (params: ProductQueryParams): Promise<ProductSettingsT | null> => {
  let product: ProductSettingsT | null = null
  if (params.urlHostname) {
    const productData = await PartnerService.getPartnerGetPartnerConfigurationByHostname(
      params.urlHostname,
    )
    if (productData) {
      product = { ...productData, isPartnerOnboarding: false }
    }
  }

  if (!product && params.productId) {
    const productData = await PartnerService.getPartnerGetPartnerConfigurationByPartnerVariantId(
      params.productId,
    )
    if (productData) {
      product = { ...productData, isPartnerOnboarding: false }
    }
  }

  if (params.isPartnerOnboarding) {
    product = {
      displayName: 'Partner Onboarding',
      isPartnerOnboarding: true,
      onboardingChannel: PartnerOnboardingChannel.PARTNER_ONBOARDING,
    } as ProductSettingsT
  }

  return product
}

export const useProductQuery = (productParams: ProductQueryParams) =>
  useQuery({
    queryKey: [QueryKey.PRODUCT],
    queryFn: async () => await getProductQuery(productParams),
    enabled:
      !!productParams.urlHostname || !!productParams.productId || !!productParams.isPartnerOnboarding,
    refetchOnWindowFocus: false,
  })

/*
 * Product Configuration
 */

const mapOnboardingChannelToConfigOverrides = async (
  onboardingChannel: OnboardingChannel | PartnerOnboardingChannel,
): Promise<Partial<PartnerFrontendConfiguration>> => {
  if (
    [
      OnboardingChannel.BROKER,
      OnboardingChannel.CREDIT,
      OnboardingChannel.PARTNER_RETAIL,
      OnboardingChannel.POSTFINANCE,
    ].includes(onboardingChannel as OnboardingChannel)
  ) {
    return (await import('../../engines/credit/credit.config.overrides')).creditConfigOverrides
  }

  if ([OnboardingChannel.POS].includes(onboardingChannel as OnboardingChannel)) {
    return (await import('../../engines/pos/pos.config.overrides')).posConfigOverrides
  }

  if (
    [OnboardingChannel.BPL_ECOMMERCE, OnboardingChannel.BPL_RETAIL].includes(
      onboardingChannel as OnboardingChannel,
    )
  ) {
    return (await import('../../engines/bpl/bpl.config.overrides')).bplConfigOverrides
  }

  if ([OnboardingChannel.RETAIL].includes(onboardingChannel as OnboardingChannel)) {
    return (await import('../../engines/retail/retail.config.overrides')).retailConfigOverrides
  }

  if ([OnboardingChannel.ECOMMERCE].includes(onboardingChannel as OnboardingChannel)) {
    return (await import('../../engines/ecommerce/ecommerce.config.overrides')).ecommerceConfigOverrides
  }

  if (
    [PartnerOnboardingChannel.PARTNER_ONBOARDING].includes(onboardingChannel as PartnerOnboardingChannel)
  ) {
    return (await import('../../engines/partnerOnboarding/partnerOnboarding.config.overrides'))
      .partnerOnboardingConfigOverrides
  }

  return {}
}

const getBrandTheme = async (brand: string): Promise<BrandTheme> => {
  if (brand === 'orca') {
    const config = await import(`../../styling/postFinance/postFinance.brand`)
    return config.brand
  }

  if (brand === 'breitling') {
    const config = await import(`../../styling/breitling/breitling.brand`)
    return config.brand
  }

  const config = await import('../../styling/bob/bob.brand')
  return config.brand
}

const getProductConfigurationQuery = async (
  product?: ProductSettingsT | null,
): Promise<PartnerFrontendConfiguration | null> => {
  let customTheme: BrandTheme | undefined = undefined
  let frontendConfiguration: PartnerFrontendConfiguration | null = null
  if (product) {
    customTheme = await getBrandTheme(product.brandStyle || 'bob')

    // Get engine config

    const engineConfigOverrides = await mapOnboardingChannelToConfigOverrides(
      product.onboardingChannel as OnboardingChannel | PartnerOnboardingChannel,
    )

    // Get product config from partner variant -> uiSettings
    const productConfigOverrides = JSON.parse(
      `${product.uiSettings || '{}'}`,
    ) as Partial<PartnerFrontendConfiguration>

    // Combine all three configs
    frontendConfiguration = {
      styleConfig:
        productConfigOverrides.styleConfig ||
        engineConfigOverrides.styleConfig ||
        defaultPartnerConfig.styleConfig,
      headerConfig: {
        ...defaultPartnerConfig.headerConfig,
        ...engineConfigOverrides.headerConfig,
        ...productConfigOverrides.headerConfig,
      },
      navigationConfig: {
        ...defaultPartnerConfig.navigationConfig,
        ...engineConfigOverrides.navigationConfig,
        ...productConfigOverrides.navigationConfig,
      },
      footerConfig: {
        ...defaultPartnerConfig.footerConfig,
        ...engineConfigOverrides.footerConfig,
        ...productConfigOverrides.footerConfig,
      },
      trackingConfig: {
        ...defaultPartnerConfig.trackingConfig,
        ...engineConfigOverrides.trackingConfig,
        ...productConfigOverrides.trackingConfig,
      },
      otherConfig: {
        ...defaultPartnerConfig.otherConfig,
        ...engineConfigOverrides.otherConfig,
        ...productConfigOverrides.otherConfig,
      },
      ...(customTheme || {}),
    } as PartnerFrontendConfiguration

    // Set logo in header options
    frontendConfiguration.headerConfig = {
      ...frontendConfiguration.headerConfig,
      logo:
        (product.assetFilenames?.companyLogoURI &&
          product.assetFilenames.companyLogoURI > '' &&
          product.assetFilenames.companyLogoURI) ||
        (frontendConfiguration.headerConfig.logo?.startsWith('/') &&
          frontendConfiguration.headerConfig.logo) ||
        `https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/${frontendConfiguration.headerConfig.logo}`,
    }
  }

  return Promise.resolve(frontendConfiguration)
}

export const useProductConfigurationQuery = (product?: ProductSettingsT | null) =>
  useQuery({
    queryKey: [QueryKey.PRODUCT_CONFIGURATION],
    queryFn: () => getProductConfigurationQuery(product),
    enabled: !!product,
    refetchOnWindowFocus: false,
  })

/*
 * Get partner frontend configurations as logged in user
 */

const getMeFrontendConfigurations = async (appConfig: AppConfig): Promise<Array<ProductSettingsT>> => {
  let products: Array<ProductSettingsT> = []
  const adToken = localStorage.getItem('azureToken')

  if (adToken) {
    products = await fetch(`${appConfig.app.whitelabelBobBackendApiUrl}/me/frontendconfigurations`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${adToken || ''}`,
      },
    })
      .then((res) => (res.status === 200 ? res.json() : []))
      .catch(() => [])
  }

  return products
}

export const useGetProductsOfLoggedInUserQuery = () => {
  const { appConfig } = useAppConfig()

  return useQuery({
    queryKey: [QueryKey.PRODUCT_CONFIGURATION_LIST],
    queryFn: () => getMeFrontendConfigurations(appConfig),
    refetchOnWindowFocus: false,
    select: (products) => products.filter((p) => p.onboardingChannel === OnboardingChannel.POS),
  })
}
