import { differenceInYears, isAfter, isBefore, parseISO } from 'date-fns'
import { isQRReferenceValid } from 'swissqrbill/lib/browser/esm/shared/utils'
import {
  ADULT_AGE,
  CITY_REGEXP,
  EMAIL_REGEX,
  LAST_NAME_REGEXP,
  LICHTENSTEIN_ZIP_LIST,
  MINIMUM_VALID_AGE_START_WORK,
  MINIMUM_VALID_DATE,
  NAME_REGEXP,
  PHONE_REGEX,
  PHONE_REGEX_UNIVERSAL,
  STREET_NAME_REGEXP,
  STREET_NUMBER_REGEXP,
  SWISSZIP_REGEXP,
  ZIP_REGEXP,
  ZeroCountryOptions,
} from './constants'

export const validateZip = (value: any) =>
  !ZIP_REGEXP.test(`${value}`) ? 'formFields.error.invalidZip' : null

// For now just checks that the PLZ is in the range between 1000 - 9999 and does not include any of the PLZ of Lichtenstein
export const validateSwissZip = (value: any) => {
  return !SWISSZIP_REGEXP.test(`${value}`) || LICHTENSTEIN_ZIP_LIST.includes(value)
    ? 'formFields.error.invalidZip'
    : null
}

// For now just checks that the PLZ is in the range between 1000 - 9999
export const validateSwissAndLichtensteinZip = (value: any) =>
  !SWISSZIP_REGEXP.test(`${value}`) ? 'formFields.error.invalidZip' : null

export const validateEmail = (value: any) =>
  !EMAIL_REGEX.test(`${value}`) ? 'formFields.error.invalidEmail' : null

export const validateMobilePhoneNr = (value: any) =>
  !PHONE_REGEX.test(`${value}`) ? 'formFields.error.invalidMobileTitle' : null

export const validateMobilePhoneNrInternational = (params: {
  phone: string | null
  countryCode: string | null
}) => {
  if (params.countryCode === null || params.phone === null || params.phone === '') return null
  if (params.countryCode === ZeroCountryOptions.CH) {
    // Validate Swiss phone number
    return !PHONE_REGEX.test(`${params.phone.replace(/^0+/, '')}`)
      ? 'formFields.error.invalidMobileTitle'
      : null
  } else {
    // Validate international phone number
    return !PHONE_REGEX_UNIVERSAL.test(`${params.countryCode}${params.phone.replace(/^0+/, '')}`)
      ? 'formFields.error.invalidMobileTitleInternational'
      : null
  }
}

export const validateDate =
  (params: { maxDate?: Date; months?: number } = { months: 0 }) =>
  (value: string) => {
    const maxDate = new Date()
    maxDate.setMonth(maxDate.getMonth() - (params.months || 0))
    maxDate.setHours(params.months ? 24 : 0)
    maxDate.setMinutes(0)
    maxDate.setSeconds(0)
    maxDate.setMilliseconds(0)

    return isAfter(new Date(value), new Date(MINIMUM_VALID_DATE)) &&
      isBefore(new Date(value), params.maxDate || maxDate)
      ? null
      : 'formFields.error.invalidDate'
  }

export const validateDateFuture =
  (params: { months?: number } = { months: 0 }) =>
  (value: string) => {
    const minDate = new Date()
    minDate.setMonth(minDate.getMonth() + (params.months || 0))
    minDate.setHours(0)
    minDate.setMinutes(0)
    minDate.setSeconds(0)
    minDate.setMilliseconds(0)

    return isAfter(new Date(value), minDate) ? null : 'formFields.error.invalidDate'
  }

export const isAgeDifferenceValid = (date1: string, date2: string): boolean => {
  // We have to check the day the person started work,
  // to ensure that the person was older than 13 years old

  // Convert the input strings to Date objects
  const d1: Date = new Date(date1) // date the person started work
  const d2: Date = new Date(date2) // date of birth

  // Calculate the age using date-fns differenceInYears function
  let age = differenceInYears(d1, d2)

  // Check if the person is 13 or older
  return age >= MINIMUM_VALID_AGE_START_WORK
}

export const isOlderThan = (date1: string, date2: string | null): string | null =>
  date2 !== null && isAgeDifferenceValid(date1, date2) && validateDate()(date1) === null
    ? null
    : 'formFields.error.invalidDate'

export const isAdult = (dateOfBirth: Date): string | null => {
  const today = new Date()

  // Calculate the age using date-fns differenceInYears function
  let age = differenceInYears(today, parseISO(`${dateOfBirth}`))

  // Check if the person is 18 or older
  return age >= ADULT_AGE ? null : 'formFields.error.invalidDate'
}

export const validateName = (value: string) =>
  !NAME_REGEXP.test(`${value}`) ? 'formFields.error.invalidText' : null

export const validateLastName = (value: string) =>
  !LAST_NAME_REGEXP.test(`${value}`) ? 'formFields.error.invalidText' : null

export const validateStreet = (value: string) =>
  !STREET_NAME_REGEXP.test(`${value}`) ? 'formFields.error.invalidText' : null

export const validateStreetNumber = (value: string) =>
  value > '' && (value.length > 7 || !STREET_NUMBER_REGEXP.test(`${value}`))
    ? 'formFields.error.invalidStreetNumber'
    : null

export const validateCity = (value: string) =>
  !CITY_REGEXP.test(`${value}`) ? 'formFields.error.invalidText' : null

export const validateCurrency = (value: number) =>
  value === 0 ? 'formFields.error.invalidCurrency' : null

export const validateEsrReference = (val: string) =>
  !val || isQRReferenceValid(val) ? null : 'formFields.error.invalidEsr'
