import { Module, ModuleConfig } from '../../types/frontend.types'

export const InvalidPhoneNumber = (): ModuleConfig => ({
  type: Module.Generic,
  moduleHeader: {
    title: 'invalidPhoneNumber.invalidMobileTitle',
  },
  description: 'invalidPhoneNumber.invalidMobileDescription',
  buttonProps: {
    label: 'invalidPhoneNumber.editPhoneNumberButtonLabel',
    onClick: () => {
      // props.dispatch(updateOrderState(ModuleSystem.EditPhoneNumber))
    },
  },
})
