import { AppConfig } from '../../../../types/app.types'
import { BobProductsApp_DTOs_BobFinanceDTO } from '../../openapi'
import { ApiError } from '../core/ApiError'

export type CreateFinancingSessionParams = {
  appConfig: AppConfig
  productId: string
  requestBody: BobProductsApp_DTOs_BobFinanceDTO
}

export type CreateFinancingSessionResponse = {
  session: string | null
}

export const createFinancingSession = async (
  params: CreateFinancingSessionParams,
): Promise<CreateFinancingSessionResponse> => {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  const azureToken = window.localStorage.getItem('azureToken')
  if (azureToken) {
    headers.append('Authorization', `Bearer ${azureToken}`)
  }

  const session = await fetch(`${params.appConfig.app.whitelabelFinancingApiUrl}/create?product=${params.productId}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(params.requestBody),
  })
    .then((res) => {
      if (res.status === 403) {
        throw new ApiError(res, 'Invalid agent pin')
      }

      if (!res.ok) {
        throw new ApiError(res, 'Error while creating financing session')
      }

      return res
    })
    .then((res) => res.text())

  return { session }
}
