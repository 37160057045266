import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { MediaQuery } from 'frr-web/lib/theme/configure.theme'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTracker } from '../context/Tracker.context'

const ADLogout = React.lazy(() => import('./authentication/ADLogout'))

export const NotFound = (props: {
  label: string
  title?: string
  hasLogout?: boolean
  subtitle?: string
}) => {
  const { tracker } = useTracker()

  useEffect(() => {
    // You can also log the error to an error reporting service
    tracker.trackException({
      exception: new Error(props.label),
      severity: SeverityLevel.Error,
    })
  }, [props.label])

  const [logout, setLogout] = React.useState(false)

  return (
    <Wrapper>
      <Container>
        <img src={'/404-error.svg'} style={{ width: 144, height: 144 }} alt='404 - not found' />
        <h1>{props.title || 'Uh oh.'}</h1>
        <p>{props.subtitle || "Looks like you're a bit lost."}</p>
        {props.hasLogout && (
          <Button
            onClick={() => {
              const win = window as any
              win.localStorage.clear()
              win.sessionStorage.clear()
              setLogout(true)
            }}
          >
            Log out
          </Button>
        )}
      </Container>
      {logout && <ADLogout />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: var(--view-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  flex-direction: column;
`
const Container = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.03);

  @media ${MediaQuery.Mobile} {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  & > h1 {
    max-width: 480px;
    font-size: 32px;
    font-weight: 700;
    margin: 24px 16px 8px;
    font-family: Helvetica;
    text-align: center;
  }

  & > h3 {
    max-width: 480px;
    font-size: 18px;
    opacity: 0.5;
    margin: 8px 16px;
    font-family: Helvetica;
    text-align: center;
  }

  & > p {
    max-width: 480px;
    font-size: 18px;
    opacity: 0.5;
    margin: 8px 16px 32px;
    font-family: Helvetica;
    text-align: center;
  }
`

const Button = styled.div`
  border: 0;
  min-width: 96px;
  padding: 4px 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: rgba(80, 80, 80, 1);
  }
`
