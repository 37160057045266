import { Div, Img, P } from 'frr-web/lib/html'
import { createStyled } from 'frr-web/lib/theme/util'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OnboardingChannel } from '../../../types/financing.types'
import { AppTheme, useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'
import { useWhitelabelNavigation } from '../../context/Navigation.context'
import { useProduct } from '../../context/PartnerProduct.context'
import { HeaderLink } from './HeaderLink'
import { Logo } from './Logo'
import { AppSidebar } from './Sidebar'
import { MenuIcon } from 'frr-web/lib/icons/MenuIcon'
// import { HeaderBanner } from './HeaderBanner'

// Dynamic imports
const HeaderMenuPos = React.lazy(() => import('./HeaderMenuPos'))
const LanguageMenu = React.lazy(() => import('./LanguageMenu'))

export type Props = {
  onResized?: () => void
  style?: Partial<AppTheme['header']>
}

// Header React component
export const Header = (props: Props) => {
  const { product, configuration } = useProduct()

  const { isChangeLanguageDisabled } = useWhitelabelNavigation()

  const [showSidebar, setShowSidebar] = useState(false)

  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'header')(props.style)

  const { t } = useTranslation()

  return (
    <>
      {/*env !== Env.Production && <HeaderBanner />*/}

      <HeaderWrapper {...getCSSStyles('outerWrapper')}>
        <Div {...getCSSStyles('innerWrapper')}>
          {configuration.headerConfig.logo && (
            <Logo
              alt={t('header.partnerName')}
              link={configuration.headerConfig.showLogoLink ? t('header.logoLink') : undefined}
              partnerName={product.displayName}
              src={configuration.headerConfig.logo}
            />
          )}

          <Div {...getCSSStyles('pageTitleWrapper')}>
            {configuration.headerConfig.showPageTitle && (
              <P {...getCSSStyles('pageTitle')} label='header.partnerPageTitle' />
            )}

            {configuration.headerConfig.showBobPageTitleLink && (
              <HeaderLink linkUrl={t('header.bobPageTitleLink')} target='_blank'>
                <P
                  {...getCSSStyles({ pageTitle: true, pageTitleRight: true })}
                  label='header.bobPageTitle'
                />
              </HeaderLink>
            )}
          </Div>

          {configuration.headerConfig.showSupportNumber &&
            product.partnerCustomerSupportPhoneNumber &&
            product.partnerCustomerSupportPhoneNumber > '' && (
              <Div {...getCSSStyles('supportNumberWrapper')}>
                <HeaderLink
                  linkUrl={`tel:${product.partnerCustomerSupportPhoneNumber.replace(' ', '')}`}
                  target='_self'
                >
                  <Img
                    alt='support'
                    {...getCSSStyles('supportIcon')}
                    src='https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/3073.png'
                  />
                  <P
                    {...getCSSStyles('supportNumberText')}
                    label={product.partnerCustomerSupportPhoneNumber}
                    isLabelTranslated
                  />
                </HeaderLink>
              </Div>
            )}

          {configuration.headerConfig.showLanguageMenu && !isChangeLanguageDisabled && <LanguageMenu />}

          {configuration.headerConfig.showSidebar && (
            <Div {...getCSSStyles('menuWrapper')}>
              <MenuIcon
                {...getCSSStyles('menuIcon')}
                dataTestId='menu'
                onClick={() => setShowSidebar(!showSidebar)}
                size={24}
              />
            </Div>
          )}

          {configuration.headerConfig.showSidebar && (
            <AppSidebar
              open={showSidebar}
              close={() => {
                setShowSidebar(false)
              }}
            />
          )}

          {product.onboardingChannel === OnboardingChannel.POS && <HeaderMenuPos />}
        </Div>
      </HeaderWrapper>
    </>
  )
}

const HeaderWrapper = createStyled(styled.div`
  position: relative;
  z-index: 20;
`)
