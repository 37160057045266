/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BobProductsApp_BusinessServices_States_External_StateEnum {
    UNKNOWN_STATE = 'UnknownState',
    PRE_INITIALIZED = 'PreInitialized',
    INITIALIZED = 'Initialized',
    SETUP_PARTNER = 'SetupPartner',
    ORDER_CONFIRMED = 'OrderConfirmed',
    APPLICATION_WITHDRAWN = 'ApplicationWithdrawn',
    APPLICATION_EXPIRED = 'ApplicationExpired',
    CONTRACT_CANCELLED = 'ContractCancelled',
    APPLICATION_DELETED = 'ApplicationDeleted',
    CLOSED_LOAN = 'ClosedLoan',
    WEBHOOK_LINK_FOR_ID_VERIFICATION = 'WebhookLinkForIdVerification',
    WEBHOOK_IDVERIFICATION_STARTED = 'WebhookIDVerificationStarted',
    WEBHOOK_IDVERIFICATION_COMPLETED = 'WebhookIDVerificationCompleted',
    WEBHOOK_UPLOAD_CUSTOMER_DOCUMENTS_AVAILABLE = 'WebhookUploadCustomerDocumentsAvailable',
    WEBHOOK_UPLOAD_CUSTOMER_DOCUMENTS_COMPLETED = 'WebhookUploadCustomerDocumentsCompleted',
    WEBHOOK_PAYMENT_PLAN_CONFIRMED = 'WebhookPaymentPlanConfirmed',
    WEBHOOK_LINK_FOR_CONTRACT_SIGNING = 'WebhookLinkForContractSigning',
    WEBHOOK_CONTRACT_SIGNED = 'WebhookContractSigned',
    WEBHOOK_UPLOAD_DELIVERY_DOCUMENTS_AVAILABLE = 'WebhookUploadDeliveryDocumentsAvailable',
    WEBHOOK_UPLOAD_DELIVERY_DOCUMENTS_COMPLETED = 'WebhookUploadDeliveryDocumentsCompleted',
    WEBHOOK_SUCCESSFUL_FINANCING = 'WebhookSuccessfulFinancing',
    SETUP_BASKET = 'SetupBasket',
    SETUP_PAYMENT_TERMS = 'SetupPaymentTerms',
    SETUP_PAYMENT_TERMS_ON_ROLLBACK = 'SetupPaymentTermsOnRollback',
    SETUP_CUSTOMER = 'SetupCustomer',
    CUSTOMER_DATA_SUBMISSION_SUCCESS = 'CustomerDataSubmissionSuccess',
    MOBILE_NR_OTP_SEND = 'MobileNrOtpSend',
    MOBILE_NR_VERIFICATION = 'MobileNrVerification',
    TECHDATA_BASKET_AVAILABILITY_BEFORE_CRIF = 'TechdataBasketAvailabilityBeforeCrif',
    PAYREXX_PROCESSING = 'PayrexxProcessing',
    PRE_CRIFROLLBACK = 'PreCRIFRollback',
    CRIFVERIFICATION = 'CRIFVerification',
    CREATE_JUMIO_SESSION = 'CreateJumioSession',
    SEND_JUMIO_SESSION_EMAIL = 'SendJumioSessionEmail',
    VALIDATE_PAYMENT_TERMS = 'ValidatePaymentTerms',
    IDVERIFICATION_MECHANISM_SELECTION = 'IDVerificationMechanismSelection',
    SEND_IDVERIFICATION_SESSION_EMAIL = 'SendIDVerificationSessionEmail',
    IDVERIFICATION = 'IDVerification',
    VALIDATIONS_AND_UPDATES_POST_IDVERIFICATION = 'ValidationsAndUpdatesPostIDVerification',
    AWAIT_IDVERIFICATION_START = 'AwaitIDVerificationStart',
    SEND_SMS_LINK_TO_CUSTOMER = 'SendSmsLinkToCustomer',
    AWAIT_PROCEEDING_AFTER_SMS_LINK_SENDING = 'AwaitProceedingAfterSmsLinkSending',
    AWAIT_IDVERIFICATION_DATA = 'AwaitIDVerificationData',
    GUARDEAN_IDVERIFICATION = 'GuardeanIDVerification',
    CUSTOMER_BASE_ID_GENERATION = 'CustomerBaseIdGeneration',
    FETCH_IDDOCUMENT_IMAGES = 'FetchIDDocumentImages',
    FETCH_IDDOCUMENT_IMAGES_AFTER_CONTRACT_SIGNING = 'FetchIDDocumentImagesAfterContractSigning',
    MAMBU_PRE_CHECKS = 'MambuPreChecks',
    ZEKVERIFICATION = 'ZEKVerification',
    TECHDATA_BASKET_AVAILABILITY = 'TechdataBasketAvailability',
    MAMBU_CHECKS = 'MambuChecks',
    MAMBU_CUSTOMER_CREATION = 'MambuCustomerCreation',
    MAMBU_LOAN_CREATION = 'MambuLoanCreation',
    PDF_GENERATION_CONTRACT_CUSTOMER_FINANCING = 'PdfGenerationContractCustomerFinancing',
    ORDER_PENDING = 'OrderPending',
    CONTRACT_OTP_SEND = 'ContractOtpSend',
    CONTRACT_VERIFICATION = 'ContractVerification',
    ORDER_SIGNED = 'OrderSigned',
    MAMBU_LOAN_APPROVAL = 'MambuLoanApproval',
    PLACE_TECHDATA_ORDER = 'PlaceTechdataOrder',
    ONBOARDING_SUCCESS = 'OnboardingSuccess',
    SEND_ORDER_CONFIRMATION_SMS = 'SendOrderConfirmationSms',
    PDF_GENERATION_INVOICE = 'PdfGenerationInvoice',
    SEND_ORDER_SUMMARY_EMAIL = 'SendOrderSummaryEmail',
    SEND_ORDER_CONFIRMATION_EMAIL = 'SendOrderConfirmationEmail',
    MAMBU_DATA_UPDATES = 'MambuDataUpdates',
    MAMBU_LOAN_DISBURSAL = 'MambuLoanDisbursal',
    MAMBU_FEE_POSTING = 'MambuFeePosting',
    MAMBU_DATA_FINAL_UPDATES = 'MambuDataFinalUpdates',
    NON_MAMBU_DATA_FINAL_UPDATES = 'NonMambuDataFinalUpdates',
    CLOSE_MAMBU_LOAN = 'CloseMambuLoan',
    CUSTOMER_EMAIL_VERIFICATION_AFTER_PAYMENT_PLAN_ACCEPTED = 'CustomerEmailVerificationAfterPaymentPlanAccepted',
    SETUP_INVOICE_FIELDS = 'SetupInvoiceFields',
    VALIDATE_PRE_AUTH_DTO = 'ValidatePreAuthDTO',
    VALIDATE_PURCHASE_DTO = 'ValidatePurchaseDTO',
    CHECK_PURCHASE = 'CheckPurchase',
    PDF_GENERATION_CONTRACT = 'PdfGenerationContract',
    SEND_CONTRACT_EMAIL = 'SendContractEmail',
    CUSTOMER_DATA_FINALIZATION = 'CustomerDataFinalization',
    OPTIN_CHECKS = 'OptinChecks',
    RISK_DECISION = 'RiskDecision',
    CHECK_IF_CUSTOMER_DOCUMENT_UPLOAD_IS_REQUIRED = 'CheckIfCustomerDocumentUploadIsRequired',
    UPLOAD_CUSTOMER_DOCUMENTS = 'UploadCustomerDocuments',
    SEND_INDICATIVE_OFFER_EMAIL = 'SendIndicativeOfferEmail',
    SUBMIT_INTO_DFS = 'SubmitIntoDfs',
    DFS_CUSTOMER_REJECTED = 'DfsCustomerRejected',
    DFS_ACTION_REQUIRED = 'DfsActionRequired',
    DFS_CALLBACK_PENDING = 'DfsCallbackPending',
    ONBOARDING_COMPLETE = 'OnboardingComplete',
    CONFIRM_CUSTOMER_RELATIONSHIP = 'ConfirmCustomerRelationship',
    DFS_START_DOCUMENTS_UPLOAD = 'DfsStartDocumentsUpload',
    DFS_AWAITING_DOCUMENTS = 'DfsAwaitingDocuments',
    CHECK_UPLOADED_APPLICATION_DOCUMENTS = 'CheckUploadedApplicationDocuments',
    DFS_DOCUMENTS_RECEIVED = 'DfsDocumentsReceived',
    APPLICATION_DOCUMENTS_REVIEW_STARTED = 'ApplicationDocumentsReviewStarted',
    DFS_OUTBOUND_NEEDED = 'DfsOutboundNeeded',
    DFS_REQUESTED_MISSING_DOCUMENTS = 'DfsRequestedMissingDocuments',
    DFS_DOCUMENTS_COMPLETE = 'DfsDocumentsComplete',
    APPLICATION_DOCUMENTS_REVIEW_COMPLETED = 'ApplicationDocumentsReviewCompleted',
    DFS_SCORING2DONE = 'DfsScoring2Done',
    DFS_DOCUMENT_GENERATION = 'DfsDocumentGeneration',
    CONTRACT_READY_TO_SIGN = 'ContractReadyToSign',
    CONTRACT_SIGNED = 'ContractSigned',
    UPLOAD_SIGNED_CONTRACT = 'UploadSignedContract',
    ORCA_SUBMISSION_CONFIRMED = 'OrcaSubmissionConfirmed',
    DFS_SUBMISSION_CONFIRMED = 'DfsSubmissionConfirmed',
    FLOW_ROUTER = 'FlowRouter',
    LMS_MIGRATION_START = 'LmsMigrationStart',
    START_CUSTOMER_SELECTION = 'StartCustomerSelection',
    CHECK_CUSTOMER_PERSONAL_DATA = 'CheckCustomerPersonalData',
    FETCH_MATCHING_CUSTOMERS_FROM_CUSTOMER_SERVICE = 'FetchMatchingCustomersFromCustomerService',
    AWAITING_CUSTOMER_SELECTION = 'AwaitingCustomerSelection',
    LMS_MIGRATION_VALIDATE_PAYMENT_TERMS = 'LmsMigrationValidatePaymentTerms',
    LMS_DAY1VALIDATE_PAYMENT_TERMS = 'LmsDay1ValidatePaymentTerms',
    MAMBU_CUSTOMER_CREATION_FROM_MIGRATION = 'MambuCustomerCreationFromMigration',
    NOTIFY_MAMBU_CUSTOMER_CREATION_TO_CUSTOMER_SERVICE = 'NotifyMambuCustomerCreationToCustomerService',
    MAMBU_CUSTOMER_CREATION_FROM_CUSTOMER_SELECTION = 'MambuCustomerCreationFromCustomerSelection',
    MAMBU_LOAN_PENDING_APPROVAL = 'MambuLoanPendingApproval',
    FINANCING_UPDATE_FROM_DISBURSAL_XML = 'FinancingUpdateFromDisbursalXml',
    UNDO_LOAN_WITHDRAWAL_IF_NECESSARY = 'UndoLoanWithdrawalIfNecessary',
    UPDATE_FINANCING_WITH_LATEST_NON_XML_LOAN_DATA_FROM_DFS = 'UpdateFinancingWithLatestNonXmlLoanDataFromDfs',
    VALIDATE_PAYMENT_TERMS_FROM_DISBURSAL_XML = 'ValidatePaymentTermsFromDisbursalXml',
    MAMBU_LOAN_UPDATE_FROM_DISBURSAL_XML = 'MambuLoanUpdateFromDisbursalXml',
    MAMBU_LOAN_UPDATE_FROM_DFS = 'MambuLoanUpdateFromDfs',
    LMS_COOLOFF_MIGRATION_START = 'LmsCooloffMigrationStart',
    UPDATE_MAMBU_CUSTOMER_DATA_AFTER_CUSTOMER_SELECTION = 'UpdateMambuCustomerDataAfterCustomerSelection',
    REGISTER_CUSTOMER_WITH_IDP_SERVICE = 'RegisterCustomerWithIdpService',
    ARCHIVE_CLOSED_LOAN_DATA = 'ArchiveClosedLoanData',
    UPDATE_FINANCING_WITH_LATEST_CUSTOMER_AND_LOAN_DATA_FROM_DFS = 'UpdateFinancingWithLatestCustomerAndLoanDataFromDfs',
    UPDATE_FINANCING_WITH_LATEST_LOAN_DATA_FROM_DFS = 'UpdateFinancingWithLatestLoanDataFromDfs',
    PROCESS_SELECTED_CUSTOMER = 'ProcessSelectedCustomer',
    SEND_REGISTRATION_EMAIL_AT_COOLOFF = 'SendRegistrationEmailAtCooloff',
    MAMBU_DATA_UPDATES_AFTER_LOAN_CREATION = 'MambuDataUpdatesAfterLoanCreation',
    SEND_PAYOUT_EMAIL_AT_DISBURSAL = 'SendPayoutEmailAtDisbursal',
    WHITELIST_MAMBU_CUSTOMER_IF_CURRENTLY_BLACKLISTED = 'WhitelistMambuCustomerIfCurrentlyBlacklisted',
    RE_BLACKLIST_MAMBU_CUSTOMER_IF_WHITELISTED = 'ReBlacklistMambuCustomerIfWhitelisted',
    ATTEMPT_AUTOMATIC_CUSTOMER_SELECTION = 'AttemptAutomaticCustomerSelection',
    BANK_IDENT_AWAIT_CUSTOMER_DECISION = 'BankIdentAwaitCustomerDecision',
    BANK_IDENT_PROCESS_START = 'BankIdentProcessStart',
    BANK_IDENT_AWAIT_URL = 'BankIdentAwaitUrl',
    BANK_IDENT_AUTHENTICATE = 'BankIdentAuthenticate',
    BANK_IDENT_FETCH_CUSTOMER_DATA = 'BankIdentFetchCustomerData',
    BANK_IDENT_STORE_CUSTOMER_IDENTITY_DOCUMENT = 'BankIdentStoreCustomerIdentityDocument',
    BANK_IDENT_PROCESS_DONE = 'BankIdentProcessDone',
    SCORING_FLOW_START = 'ScoringFlowStart',
    SCORING_ZEK_ARCHIVE_STATE = 'ScoringZekArchiveState',
    SCORING_ZEK_ARCHIVE_STATE_FOR_BROKER_MANUAL = 'ScoringZekArchiveStateForBrokerManual',
    SCORING_DATA_CHECK_STATE = 'ScoringDataCheckState',
    SCORING_CONTRACT_CREATION_STATE = 'ScoringContractCreationState',
    SCORING_AWAITING_DFS_CONTRACT_COOLOFF_STATE = 'ScoringAwaitingDfsContractCooloffState',
    SCORING_AWAITING_CUSTOMER_MATCHING_START_STATE = 'ScoringAwaitingCustomerMatchingStartState',
    SCORING_AWAITING_CUSTOMER_SELECTION_STATE = 'ScoringAwaitingCustomerSelectionState',
    SCORING_PAYOUT_APPROVAL_STATE = 'ScoringPayoutApprovalState',
    SCORING_PAYOUT_AWAITING_COOLOFF_STATE = 'ScoringPayoutAwaitingCooloffState',
    SCORING_PAYOUT_AWAITING_COOLOFF_COMPLETION_STATE = 'ScoringPayoutAwaitingCooloffCompletionState',
    SCORING_READY_TO_PAYOUT_STATE = 'ScoringReadyToPayoutState',
    SCORING_PAYOUT_DONE_STATE = 'ScoringPayoutDoneState',
    AWAITING_CUSTOMER_EMAIL_VERIFICATION = 'AwaitingCustomerEmailVerification',
    RECEIVED_CUSTOMER_EMAIL_VERIFICATION = 'ReceivedCustomerEmailVerification',
    CUSTOMER_EMAIL_VERIFICATION = 'CustomerEmailVerification',
    CUSTOMER_EMAIL_RE_VERIFICATION = 'CustomerEmailReVerification',
    CUSTOMER_PHONE_NUMBER_CONFIRMATION = 'CustomerPhoneNumberConfirmation',
    SEND_CONTRACT_REVIEW_EMAIL = 'SendContractReviewEmail',
    MAMBU_PRE_CHECKS1 = 'MambuPreChecks1',
    CREATE_IDVERIFICATION_SESSION = 'CreateIDVerificationSession',
    MAMBU_PRE_CHECKS2 = 'MambuPreChecks2',
    COMPUTE_LOAN_LIMITS = 'ComputeLoanLimits',
    REVALIDATE_PAYMENT_TERMS_BEFORE_FINALIZATION = 'RevalidatePaymentTermsBeforeFinalization',
    SALE_PRE_FINALIZATION = 'SalePreFinalization',
    SALE_FINALIZATION_CHECKS = 'SaleFinalizationChecks',
    REVALIDATE_PAYMENT_TERMS = 'RevalidatePaymentTerms',
    SALE_FINALIZATION = 'SaleFinalization',
    UPLOAD_IDENTITY_DOCUMENTS = 'UploadIdentityDocuments',
    IDENTITY_REVIEW = 'IdentityReview',
    MANUAL_REVIEW_BOB_PAY_RETAIL = 'ManualReviewBobPayRetail',
    AUTO_CREATE_MANUAL_REVIEW = 'AutoCreateManualReview',
    IDENTITY_REVIEW_BOB_PAY_RETAIL = 'IdentityReviewBobPayRetail',
    SIGNING_PROCESS_SELECTION = 'SigningProcessSelection',
    DELIVERY_CONFIRMATION_PROCESS_CHECK = 'DeliveryConfirmationProcessCheck',
    SEND_PRE_DELIVERY_CONFIRMATION_EMAIL = 'SendPreDeliveryConfirmationEmail',
    UPLOAD_ORDER_SIGNING_DOCUMENTS = 'UploadOrderSigningDocuments',
    UPLOAD_DELIVERY_DOCUMENTS = 'UploadDeliveryDocuments',
    DELIVERY_CONFIRMATION = 'DeliveryConfirmation',
    SEND_SIGNED_CONTRACT_EMAIL = 'SendSignedContractEmail',
    SEND_CONTRACT_CONFIRMATION_EMAIL = 'SendContractConfirmationEmail',
    SELECT_EMAILING_OR_SENDING_IDVERIFICATION_SESSION_LINK_VIA_PARTNER = 'SelectEmailingOrSendingIDVerificationSessionLinkViaPartner',
    SEND_IDVERIFICATION_SESSION_LINK_TO_PARTNER = 'SendIDVerificationSessionLinkToPartner',
    SELECT_EMAILING_OR_SENDING_OTP_SIGNING_LINK_VIA_PARTNER = 'SelectEmailingOrSendingOtpSigningLinkViaPartner',
    SEND_OTP_SIGNING_LINK_TO_PARTNER = 'SendOtpSigningLinkToPartner',
    CREATE_PAYMENT_PLAN_CONTRACT_PDF = 'CreatePaymentPlanContractPdf',
    SEND_PAYMENT_PLAN_CONFIRMATION_EMAIL = 'SendPaymentPlanConfirmationEmail',
}
