import { makeFormLens } from 'frr-web/lib/form/util'
import { FinancingObjectFormDataT } from '../types/form.types'
import { PartnerInfoFormDataT } from '../types/partner.types'
import { FinancingObject, FinancingObjectBE } from '../types/financing.types'

export const getFinancingObjectLens = makeFormLens<FinancingObject>()
export const getFinancingObjectFormLens = makeFormLens<FinancingObjectFormDataT>()
export const getFinancingObjectAddressFormLens =
  makeFormLens<FinancingObjectBE['customerInfo']['addresses'][0]>()

export const getPartnerFormLens = makeFormLens<PartnerInfoFormDataT>()
