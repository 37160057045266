import { range as frrUtilRange } from 'frr-web/lib/util'
import jwt_decode from 'jwt-decode'
import { roundTo } from 'round-to'
import { AppConfig } from '../types/app.types'
import {
  FinancingObject,
  FinancingObjectBE,
  PreFinancingObject,
  ProductSettingsT,
} from '../types/financing.types'
import { FinancingTermsT, RequestParams, SummaryFormState } from '../types/frontend.types'
import { PartnerSessionStorage } from '../view/providers/PartnerSession.provider'
import { getUserTypeFromSessionWithEnvVars, pollWithEnvVars } from './helpers.withEnvVars'
import { PlatformDataT } from 'frr-web/lib/hooks/usePlatform'

export const omit = <T extends { [k: string]: unknown }>(obj: T, keysIn: Array<keyof T>) =>
  Object.keys(obj).reduce(
    (o: T, k: string) => (keysIn.includes(k as keyof T) ? o : { ...o, [k]: obj[k] }),
    {} as unknown as T,
  )

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const range = frrUtilRange

// --------------------------------------------------------------
// Formatter
// --------------------------------------------------------------

const Formatter = new Intl.NumberFormat('de-CH', {
  style: 'currency',
  currency: 'CHF',
})

export const formatAmount = Formatter.format

// ------------------------------------------------------------
// Duration options for Slider
// ------------------------------------------------------------

export const getDurationValidation = (product: ProductSettingsT) => {
  const durationOptions = (product.durationOptions || [])
    .map((v) => v.value)
    .sort((a, b) => (a > b ? 1 : -1))

  return (duration: number | null) => {
    if (
      duration &&
      duration > durationOptions[0] &&
      duration < durationOptions[durationOptions.length - 1] &&
      !durationOptions.includes(duration)
    ) {
      return 'formFields.error.invalidDuration'
    }

    return null
  }
}

export const getPartnerDurationOptions = (product: ProductSettingsT) => {
  const { minNumberOfInstallments, maxNumberOfInstallments } = product

  const hasDurationOptions = (product.durationOptions || []).length > 0
  return {
    hasDurationOptions,
    durationOptions: hasDurationOptions
      ? (product.durationOptions || []).map((v) => v.value).sort((a, b) => (a > b ? 1 : -1))
      : range(minNumberOfInstallments, maxNumberOfInstallments),
  }
}
export const configureDurationSlider = (
  product: ProductSettingsT,
): {
  ariaLabelledby?: string
  max: number
  min: number
  marks: Array<{ label: string; value: number }>
  placeholder: string
  step: number | null
  validate: ((duration: number) => string | null) | undefined
} => {
  const { durationOptions, hasDurationOptions } = getPartnerDurationOptions(product)

  const validateDuration = hasDurationOptions ? getDurationValidation(product) : undefined

  return {
    ariaLabelledby: (product.durationOptions || []).length === 0 ? undefined : 'Restricted values',
    max: durationOptions[durationOptions.length - 1],
    min: durationOptions[0],
    marks: (product.durationOptions || []).map((v) => ({
      label: `${v.value}`,
      value: v.value,
    })),
    placeholder: `${durationOptions[0]}`,
    step: (product.durationOptions || []).length === 0 ? 1 : null,
    validate: validateDuration,
  }
}

export const getDurationAndAmountValidation = (
  product: ProductSettingsT,
  loanAmount: number,
): { minDurationBasedOnAmount: number; maxDurationBasedOnAmount: number } => {
  const minDurationBasedOnAmount =
    loanAmount <= (product.maxTransactionAmountSingleTerm || 0) &&
    loanAmount >= (product.minTransactionAmountSingleTerm || 0)
      ? product.minNumberOfInstallmentsSingleTerm
      : loanAmount <= (product.maxTransactionAmountShortTerm || 0) &&
          loanAmount >= (product.minTransactionAmountShortTerm || 0)
        ? product.minNumberOfInstallmentsShortTerm
        : product.minNumberOfInstallmentsLongTerm

  const maxDurationBasedOnAmount =
    loanAmount <= (product.maxTransactionAmountLongTerm || 0) &&
    loanAmount >= (product.minTransactionAmountLongTerm || 0)
      ? product.maxNumberOfInstallmentsLongTerm
      : loanAmount <= (product.maxTransactionAmountShortTerm || 0) &&
          loanAmount >= (product.minTransactionAmountShortTerm || 0)
        ? product.maxNumberOfInstallmentsShortTerm
        : product.maxNumberOfInstallmentsSingleTerm

  return {
    minDurationBasedOnAmount: minDurationBasedOnAmount || 0,
    maxDurationBasedOnAmount: maxDurationBasedOnAmount || 0,
  }
}

// --------------------------------------------------------------
// Financial calculations (incl. up rounding to 5 Rappen for CHF)
// --------------------------------------------------------------

const calcApr = (aer: number) => roundTo(12 * (Math.pow(1 + aer / 100, 1 / 12) - 1), 4)

const round005 = (n: number) => roundTo(n * 20, 0) / 20
const round005Up = (n: number) => Math.ceil(n * 20) / 20

export const roundFinancingAmount = (amount: number): number => Math.ceil(amount * 20) / 20

export const computeRetailMonthlyRate = (obj: FinancingObject | FinancingObjectBE) =>
  obj.financingInfo.duration && obj.financingInfo.amount
    ? `${roundFinancingAmount(obj.financingInfo.amount / obj.financingInfo.duration).toFixed(2)}`
    : '---'

export const calcMonthlyRate = (params: {
  amount: number
  duration: number
  aer: number
  ppiRate: number
  hasPpi: boolean
}) => {
  if (params.aer === 0) {
    // Bob Zero
    return {
      installment: params.duration > 0 ? round005Up(params.amount / params.duration) : 0,
      ppi: null,
    }
  } else {
    // Bob Credit
    const apr_m = calcApr(params.aer) / 12
    const tempCalc = Math.pow(1 + apr_m, params.duration)
    let installment = (params.amount * (apr_m * tempCalc)) / (tempCalc - 1)
    const ppi = round005(params.hasPpi ? installment * params.ppiRate : 0)
    installment = round005(installment + ppi)
    return { ppi, installment }
  }
}

export const computeInitialAmount = (
  product: ProductSettingsT,
  preFinObj?: PreFinancingObject,
  requestParams?: RequestParams,
) => {
  let initialLoanAmount = !isNaN(Number(requestParams?.requestedLoanAmount))
    ? Number(requestParams?.requestedLoanAmount)
    : null

  if (!initialLoanAmount) {
    initialLoanAmount = preFinObj?.amount || product.minTransactionAmount + product.amountOffset
  }

  return initialLoanAmount
}

export const computeInitialDuration = (
  product: ProductSettingsT,
  amount?: any,
  preFinObj?: PreFinancingObject,
  requestParams?: RequestParams,
) => {
  let initialDuration = !isNaN(Number(requestParams?.requestedLoanDuration))
    ? Number(requestParams?.requestedLoanDuration)
    : null

  let initialDurationWhenAmountSelected = amount
    ? getDurationAndAmountValidation(product, amount).minDurationBasedOnAmount
    : null

  if (!initialDuration) {
    initialDuration =
      preFinObj?.duration ||
      initialDurationWhenAmountSelected ||
      product.minNumberOfInstallments + product.durationOffset

    if (initialDuration < product.minNumberOfInstallments) {
      initialDuration = product.minNumberOfInstallments + product.durationOffset
    } else if (initialDuration > product.maxNumberOfInstallments) {
      initialDuration = product.maxNumberOfInstallments
    }
  }

  return initialDuration
}

export const computeLowerMonthlyRate = (params: {
  financingTerms: FinancingTermsT
  product: ProductSettingsT
}) =>
  calcMonthlyRate({
    aer: params.product.minInterestRate * 100,
    amount: params.financingTerms.amount,
    duration: params.financingTerms.duration,
    hasPpi: params.financingTerms.hasPpi,
    ppiRate: params.product.ppiRateMonthly,
  })

export const computeHigherMonthlyRate = (params: {
  financingTerms: FinancingTermsT
  product: ProductSettingsT
}) =>
  calcMonthlyRate({
    amount: params.financingTerms.amount,
    duration: params.financingTerms.duration,
    aer: params.product.maxInterestRate * 100,
    ppiRate: params.product.ppiRateMonthly,
    hasPpi: params.financingTerms.hasPpi,
  })

// ------------------------------------------------------------
// Map form state for bpl financing object
// ------------------------------------------------------------

export const computeBplMonthlyInstallmentRate = (obj: FinancingObject | FinancingObjectBE) =>
  obj.financingInfo.duration && obj.financingInfo.amount
    ? `${roundFinancingAmount(obj.financingInfo.amount / obj.financingInfo.duration).toFixed(2)}`
    : '---'

const getBplAddress = (financing: FinancingObject) =>
  `${financing.customerInfo.baseInfo.name} ${financing.customerInfo.baseInfo.surname}<br />` +
  `${financing.customerInfo.currentAddress.street} ${financing.customerInfo.currentAddress.houseNr}<br />` +
  `${financing.customerInfo.currentAddress.zip} ${financing.customerInfo.currentAddress.city}`

const getBplPreviousAddress = (financing: FinancingObject) =>
  `${financing.customerInfo.previousAddress.street} ${financing.customerInfo.previousAddress.houseNr}<br />` +
  `${financing.customerInfo.previousAddress.zip} ${financing.customerInfo.previousAddress.city}`

export const getFinancingInfoSummaryFormState = (financing: FinancingObject): SummaryFormState => {
  const duration = financing.financingInfo.duration || 0
  const loanAmount = financing.financingInfo.amount || 0
  return {
    // Loan
    duration,
    interestRateAmount: 0,
    loanAmount,
    monthlyRate: duration === 1 ? null : loanAmount / duration,
    provider: 'bob Finance',
    totalAmount: loanAmount, // add interest rate amount + fees
    // Addresses
    billingAddress: getBplAddress(financing),
    previousAddress: getBplPreviousAddress(financing),
    shippingAddress: getBplAddress(financing),
    // Legal
    acceptContract: false,
    acceptDataPrivacy: false,
    acceptDataPrivacyAndAGB: false,
    acceptNewsletter: false,
    email: financing.customerInfo.baseInfo.email || '',
    emailValid: false,
    linkToAGB: null,
  }
}

// ------------------------------------------------------------
// Map form state for financing object
// ------------------------------------------------------------

const getAddress = (finObj: FinancingObject) =>
  `${finObj.customerInfo.baseInfo.name} ${finObj.customerInfo.baseInfo.surname}<br />` +
  `${finObj.customerInfo.currentAddress.street} ${finObj.customerInfo.currentAddress.houseNr}<br />` +
  `${finObj.customerInfo.currentAddress.zip} ${finObj.customerInfo.currentAddress.city}`

const getPreviousAddress = (finObj: FinancingObject) =>
  `${finObj.customerInfo.previousAddress.street} ${finObj.customerInfo.previousAddress.houseNr}<br />` +
  `${finObj.customerInfo.previousAddress.zip} ${finObj.customerInfo.previousAddress.city}`

export const getSummaryFormState = (params: {
  appConfig: AppConfig
  finObj: FinancingObject
}): SummaryFormState => {
  const duration = params.finObj.financingInfo.duration || 0
  const loanAmount = params.finObj.financingInfo.amount || 0
  return {
    // Loan
    duration,
    interestRateAmount: 0,
    loanAmount,
    monthlyRate: loanAmount / duration,
    provider: 'bob Finance',
    totalAmount: loanAmount, // add interest rate amount + fees
    // Addresses
    billingAddress: getAddress(params.finObj),
    previousAddress: getPreviousAddress(params.finObj),
    shippingAddress: getAddress(params.finObj),
    // Legal
    acceptContract: false,
    acceptDataPrivacy: false,
    acceptDataPrivacyAndAGB: false,
    acceptNewsletter: false,
    email: params.finObj.customerInfo.baseInfo.email || '',
    emailValid: false,
    linkToAGB: `${params.appConfig.app.whitelabelBobBackendApiUrl}/bobfinancingfacadeonboarding/web/tcs?`,
  }
}

// ------------------------------------------------------------
// Extracted User type and return url from session token
// ------------------------------------------------------------

export const getUserTypeFromSession = getUserTypeFromSessionWithEnvVars
export const getReturnUrlFromSession = (): string | null => {
  const token = PartnerSessionStorage.getSession()

  if (token && token > '') {
    const decoded = jwt_decode(token) as {
      partner_return_url: string
    }
    return decoded.partner_return_url
  }

  return null
}

export const getPartnerExternalIdFromSession = (): string | null => {
  const token = PartnerSessionStorage.getSession()

  if (token && token > '') {
    const decoded = jwt_decode(token) as {
      partner_external_id: string | undefined
    }
    return decoded.partner_external_id ?? null
  }

  return null
}

// ------------------------------------------------------------
// Extracted expired session information from url
// ------------------------------------------------------------

export const isSessionExpired = (): boolean => {
  const search = new URLSearchParams(window.location.search)
  const searchExpiredSession = search.get('session-expired')

  return searchExpiredSession === '1'
}

// ------------------------------------------------------------
// Extracted invalid link information from url
// ------------------------------------------------------------

export const isSessionInvalid = (): boolean => {
  const search = new URLSearchParams(window.location.search)
  const searchInvalidatedSession = search.get('session-invalidated')

  return searchInvalidatedSession === '1'
}

// ------------------------------------------------------------
// Poll
// ------------------------------------------------------------

export const poll = pollWithEnvVars

// ------------------------------------------------------------
// Cookie handling
// ------------------------------------------------------------

export const setCookie = (params: { name: string; value: string; expires?: { minutes: number } }) => {
  let expires = ''
  if (params.expires) {
    const date = new Date()
    date.setTime(date.getTime() + params.expires.minutes * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = params.name + '=' + (params.value || '') + expires + '; path=/'
}

export const getCookie = (params: { name: string }) => {
  const nameEQ = params.name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const removeCookie = (params: { name: string }) => {
  // Set the expiration date in the past
  var pastDate = new Date(0)

  // Delete the cookie by setting its expiration date to a past date
  document.cookie = params.name + '=; expires=' + pastDate.toUTCString() + '; path=/'
}

// --------------------------------------------------------------
// bobpay ecom success screen
// business logic in task 9991
// --------------------------------------------------------------

export const calcSucessScreen = (financing: FinancingObject, platformData: PlatformDataT) => {
  const platformDataFromFinObj =
    financing && financing.contextInfo.platformData ? JSON.parse(financing.contextInfo.platformData) : {}

  const startDevice = platformDataFromFinObj.isPhone ? 'mobile' : 'desktop'
  const currentDevice = platformData.isPhone ? 'mobile' : 'desktop'

  // when sucess screen is on same device as start device then show "redirect to merchant page"
  if (startDevice === currentDevice) {
    return null
  }
  // when started on desktop and now on mobile then show "go back to desktop"
  if (currentDevice === 'mobile') {
    return { icon: 'bpl/returnToDesktop.gif', description: 'goBackToDesktop.description' }
  } else {
    // when started on mobile and now on desktop then show "genric" screen with no redirect
    return {
      icon: '/bpl/completed.animated.gif',
      description: 'customerConfirmation.descriptionEcommerce',
    }
  }
}
