import {
  ButtonType,
  FormFieldType,
  FormStateType,
  mkPreFinancingFormLens,
  PreFinancingObjectFormT,
} from '../../types/form.types'
import { Module, ModuleConfig } from '../../types/frontend.types'
import { Notification } from '../providers/Notification.provider'

const toggleStyle = {
  wrapper: {
    marginRight: 'calc(var(--form-field-width) - 52px)',
  },
}

const formFields: PreFinancingObjectFormT['formFields'] = ({ formState }) => [
  {
    type: FormFieldType.FormSection,
    fields: [
      [
        {
          label: { label: 'productReference.formFields.orderReference.label' },
          placeholder: 'productReference.formFields.orderReference.placeholder',
          type: FormFieldType.TextInput,
          lens: mkPreFinancingFormLens(['orderReference']),
          required: true,
        },
      ],
      [
        {
          label: { label: 'productReference.formFields.orderAmount.label' },
          type: FormFieldType.CurrencyInput,
          lens: mkPreFinancingFormLens(['amount']),
          max: formState.product.maxTransactionAmount,
          min: formState.product.minTransactionAmount,
          placeholder: '2000',
          prefix: 'CHF ',
          required: true,
        },
      ],
      [
        {
          label: {
            label: 'productReference.formFields.requirement1Title.label',
          },
          required: true,
          type: FormFieldType.Toggle,
          lens: mkPreFinancingFormLens(['isOccupancyPolicy']),
          style: toggleStyle,
        },
      ],
      [
        {
          label: {
            label: 'productReference.formFields.requirement2Title.label',
            labelData: {
              age: `${formState.product.minAge}`,
            },
          },
          required: true,
          type: FormFieldType.Toggle,
          lens: mkPreFinancingFormLens(['isAgePolicy']),
          style: toggleStyle,
        },
      ],
      [
        {
          label: {
            label: 'productReference.formFields.requirement3Title.label',
          },
          type: FormFieldType.Toggle,
          required: true,
          lens: mkPreFinancingFormLens(['isResidencyPolicy']),
          style: toggleStyle,
        },
      ],
      [
        {
          label: { label: 'productReference.formFields.merchantPin.label' },
          type: FormFieldType.CodeInput,
          lens: mkPreFinancingFormLens(['agentCode']),
          isAutoFocus: false,
          required: true,
          length: 4,
          validate: (value) => {
            return (value as string).replace(/-/g, '').length < 4 ? 'formFields.error.invalidCode' : null
          },
        },
      ],
    ],
  },
]

export const ProductReference = (): ModuleConfig => ({
  type: Module.Form,
  moduleHeader: {
    title: 'productReference.orderReferenceTitle',
    description: 'productReference.orderReferenceDescription',
  },
  hasMerchantBanner: true,

  formStateType: FormStateType.PreFinancingObject,
  formProps: {
    disableDirtyValidation: true,
    style: {
      row: {
        item: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
    onSubmit: ({ createFinancing, formState }) => {
      createFinancing({
        productId: formState.variantId,
        requestBody: {
          order: {
            ref: formState.orderReference,
            gross_amount: formState.amount || 0,
            currency: 'CHF',
          },
          payment: {
            type: 'financing',
            has_ppi: false,
            duration: formState.duration || 0,
          },
          sale: {
            agent: formState.agentCode,
          },
        },
        onError: ({ error, t }) => {
          // StatusCode 403: Invalid agent PIN
          if (error.status === 403) {
            Notification.error(`${t('formFields.error.invalidCode')}`)
          } else {
            Notification.error(`${t('error.crashTitle')}.${t('error.crashDescription')}`)
          }
        },
      })
    },
    formFields,
    buttons: [
      {
        type: ButtonType.Primary,
        label: 'navigation.next',
        isDisabled: (formState) =>
          !formState.isAgePolicy || !formState.isResidencyPolicy || !formState.isOccupancyPolicy,
        onClick: ({ submit }) => {
          submit()
        },
      },
    ],
  },
})
