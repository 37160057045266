import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { flatten } from 'flat'
import { ReactNode, useEffect } from 'react'
import { getUserTypeFromSession } from '../../shared/helpers'
import { DurationUnit, duration } from '../../shared/helpers.dateTime'
import { StateLevels } from '../../shared/helpers.stateLevel'
import {
  AppInsights_AdditionalTrackProperties,
  AppInsights_TrackProperties,
  TrackEventData,
  TrackMissingTranslation,
  Tracker,
} from '../../types/analytics.types'
import { OnboardingChannel, ProductSettingsT } from '../../types/financing.types'
import { AppConfig } from '../../types/app.types'
import { useAppConfig } from './AppConfigData.provider'
import styled from 'styled-components'
import { useTracker } from '../context/Tracker.context'

/*
 * Load application
 */
const reactPlugin = new ReactPlugin()

const loadAppInsights = (appConfig: AppConfig): ApplicationInsights | null => {
  const hasAppInsightsTracking =
    appConfig.app.whitelabelEnvironment !== 'dev' && !!appConfig.azure.appInsightsConnectionString

  let appInsights: ApplicationInsights | null = null

  if (hasAppInsightsTracking) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: appConfig.azure.appInsightsConnectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {},
        },
        disableFetchTracking: false,
        enableAjaxErrorStatusText: true,
        autoTrackPageVisitTime: true,
      },
    })

    appInsights.loadAppInsights()
  }

  return appInsights
}

// Tracking data

type RelavtiveLevelStartTime = {
  level2: {
    partner?: number
    customer?: number
  }
  level3: {
    partner?: number
    customer?: number
  }
}
const getStateLevelRelativeStartTime = (stateLevels: StateLevels) => {
  let relativeLevelStartTime: RelavtiveLevelStartTime = {
    level2: {},
    level3: {},
  }

  if (stateLevels.stateList.length > 2) {
    const PartnerStartDate = stateLevels.stateList[0].childLevels![0].levelInfo.startedAt
    const CustomerStartDate = stateLevels.stateList[2].childLevels![0].levelInfo.startedAt

    const activeLevel1Index = stateLevels.stateList.findIndex((level1) => level1.levelInfo.isActive)
    const activeLevel2Index =
      activeLevel1Index >= 0
        ? stateLevels.stateList[activeLevel1Index].childLevels.findIndex(
            (level2) => level2.levelInfo.isActive,
          )
        : -1

    const activeLevel3Index =
      activeLevel2Index >= 0
        ? stateLevels.stateList[activeLevel1Index].childLevels[activeLevel2Index].childLevels.findIndex(
            (level3) => level3.levelInfo.isActive,
          )
        : -1

    relativeLevelStartTime = {
      level2: {
        partner: duration({
          startDate: PartnerStartDate,
          endDate: new Date(), // activeLevel2.levelInfo.startedAt,
          unit: DurationUnit.Seconds,
        }),
        customer:
          activeLevel1Index >= 2
            ? duration({
                startDate: CustomerStartDate,
                endDate: new Date(), // activeLevel2.levelInfo.startedAt,
                unit: DurationUnit.Seconds,
              })
            : undefined,
      },
      level3:
        activeLevel3Index >= 0
          ? {
              partner: duration({
                startDate: PartnerStartDate,
                endDate: new Date(), // activeLevel3.levelInfo.startedAt,
                unit: DurationUnit.Seconds,
              }),
              customer:
                activeLevel1Index >= 2
                  ? duration({
                      startDate: CustomerStartDate,
                      endDate: new Date(), // activeLevel3.levelInfo.startedAt,
                      unit: DurationUnit.Seconds,
                    })
                  : undefined,
            }
          : {},
    }
  }

  return relativeLevelStartTime
}

const MissingKeys = new Set<string>()

/*
 * Prepare properties for tracker
 */
const toPascalCase = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => {
    return chr.toUpperCase()
  })
}
const getTrackProperties = (params: TrackEventData): AppInsights_TrackProperties => {
  const { finObj, product, stateLevels } = params

  const isBplFlow = [OnboardingChannel.BPL_RETAIL, OnboardingChannel.BPL_ECOMMERCE].includes(
    product.onboardingChannel as OnboardingChannel,
  )

  const platformDataFromFinObj = finObj?.contextInfo.platformData
    ? JSON.parse(finObj.contextInfo.platformData)
    : ''

  let bplInfo: AppInsights_AdditionalTrackProperties = {}
  let financingInfoData: Record<string, unknown> = {}

  if (isBplFlow && finObj && stateLevels) {
    const relativeLevelStartTime = getStateLevelRelativeStartTime(stateLevels)

    const bplLevelStates = {
      level1: { state: stateLevels.activeStateLevel1 },
      level2: {
        state: stateLevels.activeStateLevel2,
        'overall duration': relativeLevelStartTime.level2.partner,
        'customer duration': relativeLevelStartTime.level2.customer,
      },
      level3: {
        state: stateLevels.activeStateLevel3,
        'overall duration': relativeLevelStartTime.level3.partner,
        'customer duration': relativeLevelStartTime.level3.customer,
      },
    }

    financingInfoData = {
      'Financing id': finObj.contextInfo.financingId,
      'Error reason':
        finObj.errorInfo.error.isError || finObj.errorInfo.error.isErrorWithRetry
          ? finObj.errorInfo.error.identifier
          : undefined,
      'Rejection reason':
        finObj.errorInfo.error.isRejection ||
        finObj.errorInfo.error.isRejectionWithRetry ||
        finObj.errorInfo.error.isRejectionDueToLimitExceeded
          ? finObj.errorInfo.error.identifier
          : undefined,
      'Warning reason':
        finObj.errorInfo.warning.hasSmsSendoutFailed ||
        finObj.errorInfo.warning.hasSmsLinkExpiredForPartner
          ? finObj.errorInfo.error.identifier
          : undefined,
    }

    bplInfo = flatten(
      {
        BPL: {
          ...bplLevelStates,
          'verify ID:': {
            'document type': finObj
              ? finObj.idVerificationInfo.bobIdSession?.bobIdVerificationDocumentType
              : undefined,
            'is selfie required': finObj?.idVerificationInfo.isSelfieRequired,
            status: finObj?.idVerificationInfo.bobIdSession?.bobIdVerificationStatus
              ? toPascalCase(finObj?.idVerificationInfo.bobIdSession.bobIdVerificationStatus)
              : undefined,
          },
          'verify email:': {
            'is required': !product.doTrustEmailVerificationByPartner,
          },
          'confirm delivery:': {
            'is required': product.isUploadOfDeliveryDocumentsRequired,
          },
          'initial device type:': platformDataFromFinObj.phone !== false ? 'phone' : 'desktop device',
          'duration selected': finObj ? finObj.financingInfo.duration : undefined,
          'is SMS resend used': finObj
            ? finObj.otpVerificationInfo.mobile.isOtpResendAttempted
            : undefined,
        },
      },
      { delimiter: ' ' },
    ) as AppInsights_AdditionalTrackProperties
  }

  const finObjData = finObj
    ? {
        finObjState: finObj.stateLevelInfo.activeStateLevelBackend,
        'Financing id': finObj.contextInfo.financingId,
        'Error reason':
          finObj.errorInfo.error.isError || finObj.errorInfo.error.isErrorWithRetry
            ? finObj.errorInfo.error.identifier
            : undefined,
        'Rejection reason':
          finObj.errorInfo.error.isRejection ||
          finObj.errorInfo.error.isRejectionWithRetry ||
          finObj.errorInfo.error.isRejectionDueToLimitExceeded
            ? finObj.errorInfo.error.identifier
            : undefined,
      }
    : {}

  return {
    ...finObjData,
    ...financingInfoData,
    errors: params.errors,
    language: params.language,
    moduleState: params.moduleSystemInfo?.moduleState,
    moduleSystem: params.moduleSystemInfo?.moduleSystemId,
    moduleType: params.moduleSystemInfo?.moduleType,
    onboardingChannel: product.onboardingChannel as any,
    partner: product.variantId,
    'User type': getUserTypeFromSession(),
    'Device media': params.platformData.isDesktop ? 'Desktop' : 'Mobile',
    additionalTrackProps: { ...bplInfo, ...params.additionalData },
  }
}

/*
 * Tracker for redux sagas
 */

const getAppInsightsTraceId = (appInsights: ApplicationInsights) =>
  appInsights.context.telemetryTrace?.traceID ?? null

const getAppInsightsTracker = ({
  appConfig,
  appInsights,
}: {
  appConfig: Pick<AppConfig, 'app'>
  appInsights: ApplicationInsights | null
}): Tracker => ({
  hasAppInsightsTracking: !!appConfig,
  isInitialized: true,
  operationId: () => (appInsights ? getAppInsightsTraceId(appInsights) : null),

  addPartner: (product: ProductSettingsT) => {
    if (appInsights) {
      appInsights.addTelemetryInitializer((envelope) => {
        if (envelope.data) {
          envelope.data.onboardingChannel = product.onboardingChannel
          envelope.data.partner = product.variantId
        }
        let itemTags = envelope.tags
        itemTags = itemTags || []
        itemTags['ai.cloud.role'] = product.onboardingChannel
      })
    }
  },

  trackPageView: (params: TrackEventData) => {
    if (params.moduleSystemInfo?.moduleSystemId) {
      const { additionalTrackProps, ...trackProps } = getTrackProperties(params)
      if (appConfig.app.whitelabelEnvironment === 'dev') {
        console.debug('### APP INSIGHTS - TRACK PAGE VIEW ###')
        console.debug('Name', params.moduleSystemInfo?.moduleSystemId)
        console.debug('Properties', JSON.stringify(trackProps, null, 2))
        console.debug('Additional Properties', JSON.stringify(additionalTrackProps, null, 2))
        console.debug('')
      } else if (appInsights) {
        appInsights.trackPageView({
          name: params.moduleSystemInfo?.moduleSystemId,
          properties: { ...trackProps, ...additionalTrackProps },
        })
      }
    }
  },
  trackEvent: (params: { name: string } & TrackEventData) => {
    const { additionalTrackProps, ...trackProps } = getTrackProperties(params)
    if (appConfig.app.whitelabelEnvironment === 'dev') {
      console.debug('### APP INSIGHTS - TRACK EVENT ###')
      console.debug('Name', params.name)
      console.debug('Properties', JSON.stringify(trackProps, null, 2))
      console.debug('Additional Properties', JSON.stringify(additionalTrackProps, null, 2))
      console.debug('')
    } else if (appInsights) {
      appInsights.trackEvent({ name: params.name }, { ...trackProps, ...additionalTrackProps })
    }
  },

  // Track exception
  trackException: (payload) => {
    const exceptionData = {
      exception: payload.exception,
      customProperties: payload.customProperties,
      severityLevel: payload.severity,
    }
    if (appConfig?.app.whitelabelEnvironment === 'dev') {
      console.debug('### APP INSIGHTS - TRACK EXCEPTION ###')
      console.debug('ExceptionData', JSON.stringify(exceptionData, null, 2))
    } else if (appInsights) {
      appInsights.trackException(exceptionData)
    }
  },

  // Track missing translation
  trackMissingTranslation: (payload: TrackMissingTranslation) => {
    if (!MissingKeys.has(`${payload.ns}.${payload.key}`)) {
      const missingTranslationWarning = {
        name: 'Missing Translation',
        message: `Key ${payload.key} in namespace ${payload.ns} missing`,
        Key: payload.key,
        Namespace: payload.ns,
        Language: payload.language,
      }

      if (appConfig.app.whitelabelEnvironment === 'dev') {
        console.debug('### APP INSIGHTS - TRACK MISSING TRANSLATION WARNING ###')
        console.debug('Missing Translation', JSON.stringify(missingTranslationWarning, null, 2))
      } else if (appInsights) {
        appInsights.trackException({
          exception: missingTranslationWarning,
          severityLevel: SeverityLevel.Warning,
        })
      }
      MissingKeys.add(`${payload.ns}.${payload.key}`)
    }
  },
})

/*
 * AppInsights Context with React plugin
 */

export const AppInsightsTrackingComponent = withAITracking(
  reactPlugin,
  ({ children }: { children: ReactNode }) => {
    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
  },
)

/*
 * Tracking provider
 */

export const TrackingProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { setTracker, tracker } = useTracker()

  const hasTracker = appConfig.azure.appInsightsConnectionString

  useEffect(() => {
    if (hasTracker) {
      const appInsights = loadAppInsights(appConfig)
      setTracker(getAppInsightsTracker({ appConfig, appInsights }))
    }
  }, [])

  return (
    <>
      {tracker.hasAppInsightsTracking && tracker.isInitialized ? (
        <AppInsightsTrackingComponent>
          <TrackingContent>{props.children}</TrackingContent>
        </AppInsightsTrackingComponent>
      ) : tracker.isInitialized ? (
        props.children
      ) : !hasTracker ? (
        props.children
      ) : null}
    </>
  )
}

// This is required because withAITracking adds an empty div to the DOM
const TrackingContent = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
`
