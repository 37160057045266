import { Module, ModuleConfig, RetryOTPConfig } from '../../types/frontend.types'

export const RetryOTP = (config: RetryOTPConfig): ModuleConfig => ({
  type: Module.Generic,
  moduleHeader: { title: 'otp.incorrectCodeTitle' },
  description: 'otp.incorrectCodeDescription',
  buttonProps: {
    label: 'otp.buttonLabel.retryVerification',
    style: { common: { marginTop: 32 } },
    onClick: ({ setModuleSystem }) => {
      setModuleSystem(config.nextModuleSystem)
    },
  },
})
