/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BobDocumentsApp_DocumentTypeEnum } from '../models/BobDocumentsApp_DocumentTypeEnum';
import type { BobLoanApp_DTOs_ComputeMonthlyRateRequestDto } from '../models/BobLoanApp_DTOs_ComputeMonthlyRateRequestDto';
import type { BobLoanApp_DTOs_ComputeMonthlyRateResponseDTO } from '../models/BobLoanApp_DTOs_ComputeMonthlyRateResponseDTO';
import type { BobPartnerApp_DTOs_ComputeCustomerFeeDTO } from '../models/BobPartnerApp_DTOs_ComputeCustomerFeeDTO';
import type { BobProductsApp_BusinessServices_States_ExternalViews_UserFlowViewCustomerInfo } from '../models/BobProductsApp_BusinessServices_States_ExternalViews_UserFlowViewCustomerInfo';
import type { BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO } from '../models/BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO';
import type { BobProductsApp_DTOs_BobFinanceDTO } from '../models/BobProductsApp_DTOs_BobFinanceDTO';
import type { BobProductsApp_DTOs_DocumentDTO } from '../models/BobProductsApp_DTOs_DocumentDTO';
import type { Microsoft_AspNetCore_JsonPatch_Operations_Operation } from '../models/Microsoft_AspNetCore_JsonPatch_Operations_Operation';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BobFinancingFacadeOnboardingService {

    /**
     * @param clientCtx Client context of the request, corresponds to the BobPartnerVariantBaseId.
     * @param requestBody
     * @returns BobProductsApp_DTOs_BobFinanceDTO Created
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingCreateFinancing(
        clientCtx?: string,
        requestBody?: BobProductsApp_DTOs_BobFinanceDTO,
    ): CancelablePromise<BobProductsApp_DTOs_BobFinanceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/create_financing',
            query: {
                'client_ctx': clientCtx,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param amount
     * @returns BobPartnerApp_DTOs_ComputeCustomerFeeDTO Success
     * @throws ApiError
     */
    public static getBobFinancingFacadeOnboardingComputeCustomerFee(
        amount: number,
    ): CancelablePromise<Array<BobPartnerApp_DTOs_ComputeCustomerFeeDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobFinancingFacadeOnboarding/compute_customer_fee',
            query: {
                'amount': amount,
            },
        });
    }

    /**
     * @param clientCtx Client context of the request, corresponds to the BobPartnerVariantBaseId.
     * @param requestBody
     * @returns BobLoanApp_DTOs_ComputeMonthlyRateResponseDTO Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingComputeMonthlyRateBobZero(
        clientCtx?: string,
        requestBody?: BobLoanApp_DTOs_ComputeMonthlyRateRequestDto,
    ): CancelablePromise<BobLoanApp_DTOs_ComputeMonthlyRateResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/compute_monthly_rate_bob_zero',
            query: {
                'client_ctx': clientCtx,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param emailToken
     * @returns any Success
     * @throws ApiError
     */
    public static getBobFinancingFacadeOnboardingVerifyEmail(
        emailToken: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobFinancingFacadeOnboarding/verify_email',
            query: {
                'email_token': emailToken,
            },
        });
    }

    /**
     * @returns BobProductsApp_DTOs_BobFinanceDTO Success
     * @throws ApiError
     */
    public static getBobFinancingFacadeOnboardingGetStatus(): CancelablePromise<BobProductsApp_DTOs_BobFinanceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobFinancingFacadeOnboarding/get_status',
        });
    }

    /**
     * @returns BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO Success
     * @throws ApiError
     */
    public static getBobFinancingFacadeOnboardingGet(): CancelablePromise<BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobFinancingFacadeOnboarding/get',
        });
    }

    /**
     * @param requestBody
     * @returns BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO Success
     * @throws ApiError
     */
    public static patchBobFinancingFacadeOnboardingPatch(
        requestBody: Array<Microsoft_AspNetCore_JsonPatch_Operations_Operation>,
    ): CancelablePromise<BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/BobFinancingFacadeOnboarding/patch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO Success
     * @throws ApiError
     */
    public static patchBobFinancingFacadeOnboardingUpdateCustomer(
        requestBody?: BobProductsApp_BusinessServices_States_ExternalViews_UserFlowViewCustomerInfo,
    ): CancelablePromise<BobProductsApp_BusinessServices_States_ExternalViews_UserflowViewForFinancingDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/BobFinancingFacadeOnboarding/update_customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns binary Success
     * @throws ApiError
     */
    public static getBobFinancingFacadeOnboardingWebTcs(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobFinancingFacadeOnboarding/web/tcs',
        });
    }

    /**
     * @param financingUid
     * @param comment
     * @param type
     * @param clientCtx Client context of the request, corresponds to the BobPartnerVariantBaseId.
     * @param formData
     * @returns BobProductsApp_DTOs_DocumentDTO Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadCustomerDocument(
        financingUid: string,
        comment: string = '',
        type?: BobDocumentsApp_DocumentTypeEnum,
        clientCtx?: string,
        formData?: {
            asset?: Blob;
        },
    ): CancelablePromise<BobProductsApp_DTOs_DocumentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_customer_document',
            query: {
                'financing_uid': financingUid,
                'comment': comment,
                'type': type,
                'client_ctx': clientCtx,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param financingUid
     * @param clientCtx Client context of the request, corresponds to the BobPartnerVariantBaseId.
     * @returns any Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadOfCustomerDocumentsCompleted(
        financingUid: string,
        clientCtx?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_of_customer_documents_completed',
            query: {
                'financing_uid': financingUid,
                'client_ctx': clientCtx,
            },
        });
    }

    /**
     * @param comment
     * @param type
     * @param formData
     * @returns BobProductsApp_DTOs_DocumentDTO Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadFile(
        comment: string = '',
        type?: BobDocumentsApp_DocumentTypeEnum,
        formData?: {
            asset: Blob;
        },
    ): CancelablePromise<BobProductsApp_DTOs_DocumentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_file',
            query: {
                'comment': comment,
                'type': type,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param comment1
     * @param comment2
     * @param type1
     * @param type2
     * @param formData
     * @returns BobProductsApp_DTOs_DocumentDTO Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadFiles(
        comment1: string = '',
        comment2: string = '',
        type1?: BobDocumentsApp_DocumentTypeEnum,
        type2?: BobDocumentsApp_DocumentTypeEnum,
        formData?: {
            asset1: Blob;
            asset2: Blob;
        },
    ): CancelablePromise<Array<BobProductsApp_DTOs_DocumentDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_files',
            query: {
                'comment1': comment1,
                'comment2': comment2,
                'type1': type1,
                'type2': type2,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns BobProductsApp_DTOs_DocumentDTO Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadDeliveryNote(
        formData?: {
            asset: Blob;
        },
    ): CancelablePromise<BobProductsApp_DTOs_DocumentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_delivery_note',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns BobProductsApp_DTOs_DocumentDTO Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadInvoice(
        formData?: {
            asset: Blob;
        },
    ): CancelablePromise<BobProductsApp_DTOs_DocumentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_invoice',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postBobFinancingFacadeOnboardingUploadCompleted(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobFinancingFacadeOnboarding/upload_completed',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getBobFinancingFacadeOnboardingEmailIndicativeOffer(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobFinancingFacadeOnboarding/email/indicative_offer',
        });
    }

}
