import {
  Option,
  SuggestionItem,
  Suggestions,
  TextInputSuggestions,
  ZipCityItem,
} from '../types/form.types'

// ------------------------------------------------------------
// Zip / City Suggestions
// ------------------------------------------------------------

export const loadSuggestions = async (): Promise<Suggestions> => {
  const data = (await fetch('/data/zip-city-list.json')
    .then((res) => res.json())
    .catch((err) => {
      console.warn('SUGGESTIONS NOT LOADED', err)
      return []
    })) as Array<ZipCityItem>

  return {
    zipList: data.map((item) => ({
      value: item.zip,
      label: `${item.zip} ${item.city}`,
      isTranslated: true,
      data: item,
    })),
    cityList: data.map((item) => ({
      value: item.city,
      label: `${item.city} (${item.zip})`,
      isTranslated: true,
      data: item,
    })),
  }
}

export const getSuggestions =
  (suggestions: Array<SuggestionItem<ZipCityItem>>) =>
  (searchString: string): Promise<TextInputSuggestions> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const options = suggestions.filter((item) => item.value.startsWith(searchString)).splice(0, 7)
        resolve(options)
      }, 0)
    })
  }

const mapEmployerZipCity = (option: Option) => ({
  ...option,
  data: {
    ...option.data,
    employerCity: option.data.city,
    employerZip: option.data.zip,
  },
})

export const getEmployerZipCitySuggestions =
  (suggestions: Array<SuggestionItem<ZipCityItem>>) =>
  (searchString: string): Promise<TextInputSuggestions> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const zipCityOptions = suggestions
          .filter((item) => item.value.startsWith(searchString))
          .splice(0, 7)
          .map(mapEmployerZipCity)
        resolve(zipCityOptions)
      }, 0)
    })
  }
