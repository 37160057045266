import { AppConfig } from '../../../../types/app.types'
import { FinancingObjectBE } from '../../../../types/financing.types'
import { FinancingSessionStorage } from '../../../../view/providers/FinancingSession.provider'

export type SubmitCreditApplicationParams = {
  appConfig: AppConfig
  language?: string
}

export type SubmitCreditApplicationResponse = FinancingObjectBE

export const submitCreditApplication = async (
  params: SubmitCreditApplicationParams,
): Promise<SubmitCreditApplicationResponse> => {
  const headers = new Headers()
  const session = FinancingSessionStorage.getSession()
  headers.append('Authorization', `Bearer ${session}`)
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  
  const response = await fetch(`${params.appConfig.app.whitelabelFinancingApiUrl}/credit/submit_application`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(`Submit credit application financing failed (${res.status} / ${res.statusText})`)
      }
      return res
    })
    .then((res) => res.json())
    .catch((error) => {
      throw error
    })

  return response
}
