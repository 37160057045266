import { ModuleConfig, SuperModuleConfig, SuperModule } from '../types/frontend.types'
import { OTP } from '../view/superModules/OTP.super'
import { RetryOTP } from '../view/superModules/RetryOTP.super'
import { ProductReference } from '../view/superModules/ProductReference.super'
import { ZeroApplication } from '../view/superModules/ZeroApplication.super'
import { InvalidPhoneNumber } from '../view/superModules/InvalidPhoneNumber.super'
import { FixName } from '../view/superModules/FixName.super'
import { FixPhoneNumber } from '../view/superModules/FixPhoneNumber.super'
import { FixEmail } from '../view/superModules/FixEmail.super'

// super module type guard
export const mapSuperModuleToModule = (m: ModuleConfig | SuperModuleConfig): ModuleConfig => {
  if (m.type === SuperModule.OTP) {
    return OTP(m)
  }
  if (m.type === SuperModule.RetryOTP) {
    return RetryOTP(m)
  }
  if (m.type === SuperModule.ProductReference) {
    return ProductReference()
  }

  if (m.type === SuperModule.ZeroApplication) {
    return ZeroApplication()
  }

  if (m.type === SuperModule.FixPhoneNumber) {
    return FixPhoneNumber(m)
  }

  if (m.type === SuperModule.FixName) {
    return FixName()
  }

  if (m.type === SuperModule.InvalidPhoneNumber) {
    return InvalidPhoneNumber()
  }

  if (m.type === SuperModule.FixEmail) {
    return FixEmail()
  }

  return m
}
