import { Dispatch } from '@reduxjs/toolkit'
import { Option as OptionType } from 'frr-web/lib/components/menu/Menu.types'
import { FormButtonProps, FormProps } from 'frr-web/lib/form/components/Form'
import {
  FormField as FormFieldT,
  FormFieldType,
  TextInputSuggestions as TextInputSuggestionsType,
} from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'
import { DeprecatedLanguage as Language } from 'frr-web/lib/theme/language'
import { useUploadFile, useUploadMultipleFiles } from '../data/mutations/fileUpload.mutations'
import {
  useCreateFinancing,
  usePatchFinObj,
  useSetModuleSystem,
  useSubmitCreditApplication,
  useUpdateCustomer,
} from '../data/mutations/financingObject.mutations'
import { AppConfig } from './app.types'
import { FinancingObject, PreFinancingObject, ProductSettingsT } from './financing.types'
import { PartnerFrontendConfiguration } from './frontend.types'
// import { useFinancingPatch, useFinancingUpdateCustomer } from '../api/mutations/financing.mutations'

export { ButtonType } from 'frr-web/lib/components/Button'
export { FieldInputType, FormFieldType } from 'frr-web/lib/form/components/types'

export enum FormStateType {
  FinancingObject = 'FinancingObject',
  PreFinancingObject = 'PreFinancingObject',
}

export type FormField<T> = FormFieldT<T>
export type Option = OptionType
export type TextInputSuggestions = TextInputSuggestionsType

export type ZipCityItem = {
  zip: string
  city: string
}

export type SuggestionItem<T extends {}> = {
  value: string
  label: string
  isTranslated: true
  data: T
}

export type Suggestions = {
  cityList: Array<SuggestionItem<ZipCityItem>>
  zipList: Array<SuggestionItem<ZipCityItem>>
}

export type ComputeFormFields<FormData> = (params: {
  appConfig: AppConfig
  configuration: PartnerFrontendConfiguration
  formState: FormData
  onChangeInputType?: (params: { id: string; type: FormFieldType }) => void
  setModuleSystem: ReturnType<typeof useSetModuleSystem>
  suggestions: Suggestions
}) => Array<FormField<FormData>>

export type ComputeFinancingFormFields = ComputeFormFields<FinancingObjectFormDataT>

// --------------------------------------------------------------------------------
// Financing types for form using the FinancingObject
// --------------------------------------------------------------------------------

type FormT<FormData> = Omit<
  FormProps<FormData>,
  'buttons' | 'data' | 'display' | 'onChange' | 'formFields' | 'onSubmit'
> & {
  buttons?: Array<
    Omit<FormButtonProps<FormData>, 'onClick'> & {
      onClick: (params: {
        dispatch: Dispatch
        patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
        submit: () => void
        setModuleSystem: ReturnType<typeof useSetModuleSystem>
      }) => void
    }
  >
  formFields: Array<FormField<FormData>> | ComputeFormFields<FormData>
  hasZipCitySuggestions?: boolean
  hasPlatformData?: boolean
  onChangeInputType?: (params: { id: string; type: FormFieldType }) => void
}

export type FinancingObjectFormDataT = FinancingObject & {
  product: ProductSettingsT
} & {
  preFinObj: PreFinancingObject
}

export type FinancingObjectFormT = FormT<FinancingObjectFormDataT> & {
  onSubmit?: (params: {
    createFinancing: ReturnType<typeof useCreateFinancing>['mutateAsync']
    finObj?: FinancingObject
    formState: FinancingObjectFormDataT
    language: Language
    patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
    setModuleSystem: ReturnType<typeof useSetModuleSystem>
    submitCreditApplication: ReturnType<typeof useSubmitCreditApplication>['mutateAsync']
    updateCustomer: ReturnType<typeof useUpdateCustomer>['mutateAsync']
    uploadFile: ReturnType<typeof useUploadFile>['uploadFile']['mutateAsync']
    uploadMultipleFiles: ReturnType<typeof useUploadMultipleFiles>['uploadMultipleFiles']['mutateAsync']
  }) => void
}

export type FinancingObjectFormProps = {
  formStateType: FormStateType.FinancingObject
  formProps: FinancingObjectFormT
}

export type FinancingObjectFormFields = Array<FormField<FinancingObjectFormDataT>>

// --------------------------------------------------------------------------------
// Pre financing types for form using the PreFinancingObject & FinancingObject
// --------------------------------------------------------------------------------

export type ComputePreFinancingFormFields = ComputeFormFields<PreFinacingFormDataT>

export type PreFinancingObjectFormT = FormT<PreFinacingFormDataT> & {
  onSubmit?: (params: {
    createFinancing: ReturnType<typeof useCreateFinancing>['mutateAsync']
    finObj?: FinancingObject
    formState: PreFinacingFormDataT
    language: Language
    patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
    setModuleSystem: ReturnType<typeof useSetModuleSystem>
    updateCustomer: ReturnType<typeof useUpdateCustomer>['mutateAsync']
    uploadFile: ReturnType<typeof useUploadFile>['uploadFile']['mutateAsync']
    uploadMultipleFiles: ReturnType<typeof useUploadMultipleFiles>['uploadMultipleFiles']['mutateAsync']
  }) => void
}

export type PreFinancingFormFields = Array<FormField<PreFinacingFormDataT>>

export const mkPreFinancingFormLens = makeFormLens<PreFinacingFormDataT>()

export type PreFinacingFormDataT = PreFinancingObject & {
  product: ProductSettingsT
}

export type PreFinancingObjectFormProps = {
  formStateType: FormStateType.PreFinancingObject
  formProps: PreFinancingObjectFormT
}

export const mkFinancingObjectLens = makeFormLens<FinancingObject>()
