import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Engine } from '../../types/frontend.types'
import { useEngine } from '../context/Engine.context'
import { useOptionalFinObj } from '../context/Data.context'
import { useProduct } from '../context/PartnerProduct.context'

const partnerSessions = [Engine.POS, Engine.Ecommerce, Engine.RetailPartner]

export const HelmetProvider = () => {
  const { product, configuration } = useProduct()
  const finObj = useOptionalFinObj()
  const engine = useEngine()

  const { t, i18n } = useTranslation(configuration.otherConfig.languageNamespacePartner)

  const title: string =
    (partnerSessions.includes(engine.type) &&
      finObj &&
      finObj.contextInfo.financingId &&
      finObj.customerInfo.baseInfo.name &&
      finObj.customerInfo.baseInfo.surname &&
      `${finObj.contextInfo.financingId} - ${finObj.customerInfo.baseInfo.name} ${finObj.customerInfo.baseInfo.surname}`) ||
    (configuration.otherConfig.languageNamespacePartner &&
      t('header.htmlTitle') !== 'header.htmlTitle' &&
      t('header.htmlTitle')) ||
    product.displayName ||
    ''

  return (
    <Helmet htmlAttributes={{ lang: i18n.language, translate: 'no' }}>
      <title>{title}</title>
      <link
        rel='shortcut icon'
        href={`https://stbobfspfrontendassets.blob.core.windows.net/bobzeroassets/${configuration.headerConfig.favicon}`}
      />
    </Helmet>
  )
}
