import React, { ReactNode, useContext, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { OnboardingChannel } from '../../types/financing.types'
import { Env } from '../../types/app.types'
import { Loading } from '../scaffold/Loading'
import { useProduct } from './PartnerProduct.context'
import { useAppConfig } from '../providers/AppConfigData.provider'

type EnvContextT = {
  env: Env | null
  setEnv: (env: Env) => void
}
export const useEnv = (): EnvContextT => {
  const env = useContext(EnvContext)

  if (!env) {
    throw new Error('Env not found')
  }

  return env
}

export const EnvContext = React.createContext<EnvContextT>({
  env: null,
  setEnv: () => {},
})
EnvContext.displayName = 'EnvContext'

const mapEnvToURI: Record<Env, string> = {
  [Env.Dev]: 'http://localhost:3002',
  [Env.Test]: 'https://bob:bobson@application.test.bob.ch',
  [Env.Stage]: 'https://bob:bobson@application.stage.bob.ch',
  [Env.Blue]: 'https://bob:bobson@application.blue.bob.ch',
  [Env.Preprod]: 'https://bob:bobson@application.preprod.bob.ch',
  [Env.Prod]: 'https://application.prod.bob.ch',
}

const getEnvByLocation = (origin: string) => {
  if (origin.includes('application.bob.ch')) {
    return Env.Prod
  } else if (origin.includes('blue.bob.ch')) {
    return Env.Blue
  } else if (origin.includes('prod.bob.ch')) {
    return Env.Prod
  } else if (origin.includes('test.bob.ch')) {
    return Env.Test
  } else if (origin.includes('stage.bob.ch')) {
    return Env.Stage
  } else if (origin.includes('preprod.bob.ch')) {
    return Env.Preprod
  }else if (origin.includes('localhost')) {
    return Env.Dev
  } else {
    return Env.Prod
  }
}

export const EnvProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { product } = useProduct()
  const origin = window.location.origin
  const startEnv = getEnvByLocation(origin)
  const [env, setEnv] = React.useState<Env>(startEnv)
  const params = useParams<{ lang?: string }>()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (product.onboardingChannel === OnboardingChannel.POS && !product.isActive) {
      setEnv(appConfig.app.whitelabelEnvironment)
    }
  }, [product])

  useEffect(() => {
    const nextEnv = getEnvByLocation(origin)
    setEnv(nextEnv)
  }, [origin])

  useEffect(() => {
    if (product.onboardingChannel === OnboardingChannel.POS && env !== startEnv) {
      console.log('SWITCH ENV', env, product.variantId)
      const envUrl = mapEnvToURI[env]
      searchParams.set('partner', product.variantId)
      const search = searchParams.toString()
      const url = `${envUrl}/${params.lang || ''}${search > '' ? '?' : ''}${search}`
      window.open(url, '_self')
    }
  }, [env])

  return !env ? (
    <Loading label='loading environment' />
  ) : (
    <EnvContext.Provider value={{ env, setEnv }}>{props.children}</EnvContext.Provider>
  )
}
