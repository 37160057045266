import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreatePartnerOnboardingSession } from '../../data/mutations/partnerOnboarding'
import { PartnerInfo } from '../../types/partner.types'
import { FinancingSessionStorage } from './FinancingSession.provider'

type PartnerOnboardingData = { partnerInfo?: PartnerInfo; moduleSystem?: string }


export const PartnerSessionStorage = {
  saveSession: (session: string) => window.sessionStorage.setItem('partner.session', session),
  removeSession: () => window.sessionStorage.removeItem('partner.session'),
  getSession: () => window.sessionStorage.getItem('partner.session') ?? null,
}

export const PartnerDataStorage = {
  saveData: (data: PartnerOnboardingData) =>
    window.sessionStorage.setItem('partner.onboarding-data', JSON.stringify(data)),
  getData: () => {
    const dataString = window.sessionStorage.getItem('partner.onboarding-data')

    if (dataString && dataString > '') {
      try {
        return JSON.parse(dataString)
      } catch (e) {
        console.error(e)
        return {}
      }
    }

    return {}
  },
  removeData: () => window.sessionStorage.removeItem('partner.onboarding-data'),
}

export const usePartnerSessionOnboardingData = (): {
  partnerOnboardingData: {
    get: () => PartnerOnboardingData
    set: (v: PartnerOnboardingData) => void
    remove: () => void
  }
} => {
  return {
    partnerOnboardingData: {
      get: PartnerDataStorage.getData,
      set: PartnerDataStorage.saveData,
      remove: PartnerDataStorage.removeData,
    },
  }
}

const getPartnerOnboardingQueryString = (queryString: string) => {
  let mappedQueryString = queryString > '' && queryString.startsWith('?') ? `${queryString}&` : `?`
  mappedQueryString = `${mappedQueryString}isPartnerOnboarding=true`
  return mappedQueryString
}

export const PartnerSessionProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const session = FinancingSessionStorage.getSession()
  const [sessionLoaded, setSessionLoaded] = useState(false)

  const { createPartnerOnboardingSession } = useCreatePartnerOnboardingSession()

  useEffect(() => {
    if (session) {
      PartnerSessionStorage.removeSession()
      PartnerSessionStorage.saveSession(session)
      setSessionLoaded(true)
    } else if (!sessionLoaded) {
      // Get new session via the public partner onboarding API endpoint
      createPartnerOnboardingSession.mutateAsync().then((newSession) => {
        if (newSession) {
          PartnerSessionStorage.removeSession()
          PartnerSessionStorage.saveSession(newSession)
          setSessionLoaded(true)
        }
      })
    }
  }, [session])

  useEffect(() => {
    if (sessionLoaded && location.search.indexOf('isPartnerOnboarding') === -1) {
      navigate(`${location.pathname}${getPartnerOnboardingQueryString(location.search)}`, {
        replace: true,
      })
    }
  }, [sessionLoaded, location.pathname, location.search, navigate])

  return sessionLoaded ? <>{props.children}</> : null
}
