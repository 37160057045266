/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BobPartnerApp_DTOs_PartnerOnboardingDTO } from '../models/BobPartnerApp_DTOs_PartnerOnboardingDTO';
import type { BobPartnerApp_DTOs_PartnerOnboardingStatusDTO } from '../models/BobPartnerApp_DTOs_PartnerOnboardingStatusDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BobPartnerFacadeOnboardingService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getBobPartnerFacadeOnboardingCreatePublicOnboardingLink(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobPartnerFacadeOnboarding/create_public_onboarding_link',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getBobPartnerFacadeOnboardingCreateOnboardingLink({
        returnUrl,
        partnerId,
        clientCtx,
    }: {
        returnUrl?: string,
        partnerId?: string,
        /**
         * Client context of the request, corresponds to the BobPartnerVariantBaseId.
         */
        clientCtx?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobPartnerFacadeOnboarding/create_onboarding_link',
            query: {
                'returnUrl': returnUrl,
                'partnerId': partnerId,
                'client_ctx': clientCtx,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postBobPartnerFacadeOnboardingCreatePartner({
        requestBody,
    }: {
        requestBody?: BobPartnerApp_DTOs_PartnerOnboardingDTO,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobPartnerFacadeOnboarding/create_partner',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postBobPartnerFacadeOnboardingCreatePartnerOnboarding({
        clientCtx,
        requestBody,
    }: {
        /**
         * Client context of the request, corresponds to the BobPartnerVariantBaseId.
         */
        clientCtx?: string,
        requestBody?: BobPartnerApp_DTOs_PartnerOnboardingDTO,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/BobPartnerFacadeOnboarding/create_partner_onboarding',
            query: {
                'client_ctx': clientCtx,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BobPartnerApp_DTOs_PartnerOnboardingStatusDTO Success
     * @throws ApiError
     */
    public static getBobPartnerFacadeOnboardingGetOnboardingStatus({
        showAll = false,
        clientCtx,
    }: {
        showAll?: boolean,
        /**
         * Client context of the request, corresponds to the BobPartnerVariantBaseId.
         */
        clientCtx?: string,
    }): CancelablePromise<Array<BobPartnerApp_DTOs_PartnerOnboardingStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobPartnerFacadeOnboarding/get_onboarding_status',
            query: {
                'showAll': showAll,
                'client_ctx': clientCtx,
            },
        });
    }

    /**
     * @returns BobPartnerApp_DTOs_PartnerOnboardingStatusDTO Success
     * @throws ApiError
     */
    public static getBobPartnerFacadeOnboardingGetOnboardingOverview({
        showAll = false,
        clientCtx,
    }: {
        showAll?: boolean,
        /**
         * Client context of the request, corresponds to the BobPartnerVariantBaseId.
         */
        clientCtx?: string,
    }): CancelablePromise<Array<BobPartnerApp_DTOs_PartnerOnboardingStatusDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/BobPartnerFacadeOnboarding/get_onboarding_overview',
            query: {
                'showAll': showAll,
                'client_ctx': clientCtx,
            },
        });
    }

}
