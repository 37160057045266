import { ZERO_COUNTRY_CODE_OPTIONS } from '../../shared/constants'
import { getSuggestions } from '../../shared/helpers.suggestions'
import {
  validateDate,
  validateEmail,
  validateMobilePhoneNrInternational,
  validateSwissAndLichtensteinZip,
  validateSwissZip,
} from '../../shared/validations'
import { Gender } from '../../types/financing.types'
import { ButtonType, FinancingObjectFormT, FormFieldType, FormStateType } from '../../types/form.types'
import { Module, ModuleConfig } from '../../types/frontend.types'
import { getFinancingObjectFormLens } from '../../shared/helpers.lenses'

const formFields: FinancingObjectFormT['formFields'] = ({ suggestions }) => [
  {
    type: FormFieldType.FormSection,
    title: 'zeroApplication.personInfoTitle',
    fields: [
      [
        {
          label: { label: 'formFields.salutation.label' },
          type: FormFieldType.RadioGroup,
          lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'gender']),
          options: [
            {
              label: 'formFields.salutation.options.mr',
              value: Gender.Male,
            },
            {
              label: 'formFields.salutation.options.mrs',
              value: Gender.Female,
            },
          ],
          required: true,
        },
      ],
      [
        {
          label: {
            label: 'formFields.firstName.label',
            sublabel: 'formFields.firstName.sublabel',
          },
          type: FormFieldType.TextInput,
          lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'name']),
          required: true,
        },
      ],
      [
        {
          label: {
            label: 'formFields.lastName.label',
            sublabel: 'formFields.lastName.sublabel',
          },
          type: FormFieldType.TextInput,
          lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'surname']),
          required: true,
        },
      ],
      [
        {
          label: { label: 'formFields.birthDate.label' },
          type: FormFieldType.MaskedDatePicker,
          dateFormat: 'yyyy-MM-dd',
          displayDateFormat: 'dd.MM.yyyy',
          lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'dateOfBirth']),
          required: true,
          validate: validateDate(),
        },
      ],
      [
        {
          label: { label: 'formFields.nationality.label' },
          type: FormFieldType.CountrySelect,
          lens: getFinancingObjectFormLens(['customerInfo', 'identity', 'nationality']),
          priority: ['CHE', 'DEU', 'FRA', 'ITA'],
          required: true,
        },
      ],
      {
        type: FormFieldType.MultiInput,
        label: { label: 'formFields.streetNameAndNr.label' },
        fields: [
          {
            itemStyle: {
              marginRight: 0,
            },
            name: 'street',
            type: FormFieldType.TextInput,
            lens: getFinancingObjectFormLens(['customerInfo', 'currentAddress', 'street']),
            required: true,
          },
          {
            style: {
              wrapper: {
                minWidth: 'var(--multi-form-field-street-nr-width)',
                maxWidth: 'var(--multi-form-field-street-nr-width)',
              },
            },
            name: 'houseNumber',
            itemStyle: {
              marginLeft: 0,
            },
            type: FormFieldType.TextInput,
            lens: getFinancingObjectFormLens(['customerInfo', 'currentAddress', 'houseNr']),
          },
        ],
      },
      {
        type: FormFieldType.MultiInputAutosuggest,
        label: {
          label: 'formFields.zipAndCity.label',
          style: { wrapper: { minWidth: 200 } },
        },
        fields: [
          {
            style: {
              wrapper: {
                maxWidth: 'var(--multi-form-field-zip-width)',
                minWidth: 'var(--multi-form-field-zip-width)',
              },
            },
            itemStyle: { marginRight: 0 },
            lens: getFinancingObjectFormLens(['customerInfo', 'currentAddress', 'zip']),
            name: 'zip',
            onLoadSuggestions: getSuggestions(suggestions.zipList),
            required: true,
            type: FormFieldType.TextInputAutosuggest,
            validate: validateSwissZip,
          },
          {
            itemStyle: { marginLeft: 0 },
            lens: getFinancingObjectFormLens(['customerInfo', 'currentAddress', 'city']),
            name: 'city',
            onLoadSuggestions: getSuggestions(suggestions.cityList),
            required: true,
            type: FormFieldType.TextInputAutosuggest,
          },
        ],
      },
      [
        {
          label: {
            label: 'formFields.hasMovedRecently.label',
          },
          type: FormFieldType.YesNoRadioGroup,
          required: true,
          lens: getFinancingObjectFormLens(['customerInfo', 'residence', 'hasMovedRecently']),
        },
      ],
    ],
  },
  {
    isVisible: (data) => !!data.customerInfo.residence.hasMovedRecently,
    type: FormFieldType.FormSection,
    title: 'zeroApplication.previousAddressTitle',
    fields: [
      {
        type: FormFieldType.MultiInput,
        label: {
          label: 'formFields.streetNameAndNr.label',
          style: { wrapper: { minWidth: 200 } },
        },
        fields: [
          {
            itemStyle: {
              marginRight: 0,
            },
            required: true,
            type: FormFieldType.TextInput,
            lens: getFinancingObjectFormLens(['customerInfo', 'previousAddress', 'street']),
          },
          {
            style: {
              wrapper: {
                minWidth: 'var(--multi-form-field-street-nr-width)',
                maxWidth: 'var(--multi-form-field-street-nr-width)',
              },
            },
            itemStyle: {
              marginLeft: 0,
            },
            type: FormFieldType.TextInput,
            maxLength: 4,
            lens: getFinancingObjectFormLens(['customerInfo', 'previousAddress', 'houseNr']),
          },
        ],
      },
      {
        type: FormFieldType.MultiInputAutosuggest,
        label: {
          label: 'formFields.zipAndCity.label',
          style: { wrapper: { minWidth: 200 } },
        },
        fields: [
          {
            style: {
              wrapper: {
                minWidth: 'var(--multi-form-field-zip-width)',
                maxWidth: 'var(--multi-form-field-zip-width)',
              },
            },
            itemStyle: { marginRight: 0 },
            lens: getFinancingObjectFormLens(['customerInfo', 'previousAddress', 'zip']),
            onLoadSuggestions: getSuggestions(suggestions.zipList),
            required: true,
            type: FormFieldType.TextInputAutosuggest,
            validate: validateSwissAndLichtensteinZip,
          },
          {
            itemStyle: { marginLeft: 0 },
            lens: getFinancingObjectFormLens(['customerInfo', 'previousAddress', 'city']),
            onLoadSuggestions: getSuggestions(suggestions.cityList),
            required: true,
            type: FormFieldType.TextInputAutosuggest,
          },
        ],
      },
    ],
  },
  {
    type: FormFieldType.FormSection,
    title: 'zeroApplication.contactInformation',
    description: 'zeroApplication.contactInformationDescription',
    fields: [
      [
        {
          label: { label: 'formFields.email.label' },
          required: true,
          name: 'email',
          type: FormFieldType.TextInput,
          lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'email']),
          validate: validateEmail,
        },
      ],
      {
        type: FormFieldType.MultiInput,
        label: {
          label: 'formFields.phoneNumber.label',
        },
        fields: [
          {
            style: {
              wrapper: {
                maxWidth: 'var(--multi-form-field-country-code-width)',
                minWidth: 'var(--multi-form-field-country-code-width)',
              },
              select: {
                width: '100%',
              },
            },
            itemStyle: {
              marginRight: 0,
            },
            options: ZERO_COUNTRY_CODE_OPTIONS,
            required: true,
            type: FormFieldType.TextSelect,
            lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'countryCode']),
          },
          {
            itemStyle: {
              marginLeft: 0,
            },
            name: 'phone',
            required: true,
            validate: (value, formState) =>
              validateMobilePhoneNrInternational({
                phone: value,
                countryCode: formState.customerInfo.baseInfo.countryCode,
              }),
            type: FormFieldType.TextInput,
            lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'phone']),
          },
        ],
      },
    ],
  },
  {
    type: FormFieldType.FormSection,
    title: '',
    description: 'zeroApplication.termsAndConditionsDescription',
    fields: [],
  },
]

const languageLens = getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'language'])

export const ZeroApplication = (): ModuleConfig => ({
  type: Module.Form,
  moduleHeader: {
    title: 'zeroApplication.financingApplicationTitle',
    description: 'zeroApplication.financingApplicationDescription',
  },
  formStateType: FormStateType.FinancingObject,
  formProps: {
    buttons: [
      {
        type: ButtonType.Primary,
        label: 'zeroApplication.submitButtonLabel',
        onClick: ({ submit }) => {
          submit()
        },
      },
    ],
    formFields,
    hasZipCitySuggestions: true,
    onSubmit: ({ updateCustomer, formState, language }) => {
      updateCustomer({
        finObj: languageLens.set(language)(formState),
        optIns: {
          privacyPolicyAccepted: true,
          proceedToCustomerDataFinalization: true,
        },
      })
    },
  },
})
