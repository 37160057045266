import { FormButtonProps } from 'frr-web/lib/form/components/Form'
import { usePatchFinObj, useSetModuleSystem } from '../../data/mutations/financingObject.mutations'
import { FinancingObject } from '../../types/financing.types'
import {
  ButtonType,
  FinancingObjectFormFields,
  FormFieldType,
  FormStateType,
} from '../../types/form.types'
import { Module, ModuleConfig, OTPConfig } from '../../types/frontend.types'
import { Notification } from '../providers/Notification.provider'
import { getFinancingObjectFormLens } from '../../shared/helpers.lenses'

const formFields = (
  type: 'mobile' | 'contract',
  phoneNumber: string,
  isOTPSendError: boolean,
): FinancingObjectFormFields => [
  {
    type: FormFieldType.FormSection,
    fields: [
      {
        type: FormFieldType.CodeInput,
        length: 7,
        label: {
          label: isOTPSendError ? 'otp.sentToThisPhoneNumberFailed' : 'otp.sentToThisPhoneNumber',
          labelData: { phoneNumber },
          style: {
            wrapper: { marginBottom: 24, marginRight: 0 },
            labelText: isOTPSendError
              ? {
                  color: 'var(--color-error)',
                }
              : undefined,
          },
        },
        style: {
          wrapper: {
            width: 'fit-content',
          },
        },
        isAutoFocus: true,
        lens: getFinancingObjectFormLens(['otpVerificationInfo', type, 'otpValue']),
      },
    ],
  },
]
const isMobileOTPDisabled = (formState: FinancingObject) => {
  if (formState.errorInfo.error.identifier === 'is_err_otp_send_failed') return true

  const otpCode = formState.otpVerificationInfo.mobile.otpValue || ''

  return otpCode.replace(/-/g, '').length < 7
}
const isContractOTPDisabled = (formState: FinancingObject) => {
  if (formState.errorInfo.error.identifier === 'is_err_otp_send_failed') return true

  const otpCode = formState.otpVerificationInfo.contract.otpValue || ''

  return otpCode.replace(/-/g, '').length < 7
}

export const OTP = (config: OTPConfig): ModuleConfig => ({
  type: Module.Form,
  formStateType: FormStateType.FinancingObject,
  moduleHeader: {
    title: config.title,
    description: config.description,
  },
  formProps: {
    onSubmit: ({ patchFinObj, formState, setModuleSystem }) => {
      patchFinObj({
        updates: [
          {
            op: 'add',
            path: '/otpInput/otp',
            value:
              config.OTPtype === 'mobile'
                ? formState.otpVerificationInfo.mobile.otpValue
                : formState.otpVerificationInfo.contract.otpValue,
          },
          {
            op: 'add',
            path: '/otpInput/otpType',
            value: config.OTPtype,
          },
          ...((!!formState.otpVerificationInfo.mobile.otpValuePrevious && config.OTPtype === 'mobile') ||
          (!!formState.otpVerificationInfo.contract.otpValuePrevious && config.OTPtype === 'contract')
            ? [
                {
                  op: 'add',
                  path: '/otpInput/reverifyCustomerOtp',
                  value: true,
                },
              ]
            : []),
        ],
      })
    },
    style: {
      row: {
        item: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
    },
    formFields: ({ formState }) =>
      formFields(
        config.OTPtype,
        ` ${formState.customerInfo.baseInfo.countryCode} ${formState.customerInfo.baseInfo.phone}`,
        formState.errorInfo.error.identifier === 'is_err_otp_send_failed',
      ),
    buttons: [
      ...((config.canUpdateNumber
        ? [
            {
              type: ButtonType.Secondary,
              label: 'otp.buttonLabel.updatePhoneNumber',
              onClick: ({ setModuleSystem }) => {
                setModuleSystem('FixPhoneNumber')
              },
            },
          ]
        : []) as Array<
        Omit<FormButtonProps<FinancingObject>, 'onClick'> & {
          onClick: (params: {
            patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
            submit: () => void
            setModuleSystem: ReturnType<typeof useSetModuleSystem>
          }) => void
        }
      >),
      {
        type: ButtonType.Secondary,
        label: 'otp.buttonLabel.resendCode',
        onClick: ({ patchFinObj }) => {
          patchFinObj({
            updates: [
              {
                value: true,
                path: '/otpInput/resendOtpToCustomer',
                op: 'replace',
              },
            ],
            isSameModuleSystem: true,
            onSuccess: (finObj, t) => {
              if (finObj.otpVerificationInfo.mobile.isOtpSentSuccessfullyToUser) {
                Notification.success(t('otp.resendSMS.success'))
              }
            },
          })
        },
      },
      {
        type: ButtonType.Primary,
        label: 'otp.buttonLabel.submitCode',
        isDisabled: (formState) =>
          config.OTPtype === 'mobile'
            ? isMobileOTPDisabled(formState)
            : isContractOTPDisabled(formState),
        onClick: ({ submit }) => {
          submit()
        },
      },
    ] as Array<
      Omit<FormButtonProps<FinancingObject>, 'onClick'> & {
        onClick: (params: {
          patchFinObj: ReturnType<typeof usePatchFinObj>['mutateAsync']
          submit: () => void
          setModuleSystem: ReturnType<typeof useSetModuleSystem>
        }) => void
      }
    >,
  },
})
