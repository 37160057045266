import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense } from 'react'
import { ModuleDelegator } from './components/delegator/ModuleDelegator'
import { Loading } from './scaffold/Loading'
import { BrowserError } from './scaffold/error/BrowserError'
import { ErrorBoundary } from './scaffold/error/ErrorBoundary'

// Providers
import { GoogleTagManagerProvider } from './context/GoogleTagManager.context'
import { AcceptCookieProvider } from './providers/AcceptCookie.provider'
import { TrackingProvider } from './providers/Tracking.provider'
import { AppVersionProvider } from './providers/AppVersion.provider'
import { EmailValidationProvider } from './providers/EmailValidation.provider'
import { HelmetProvider } from './providers/Helmet.provider'
import { NotificationProvider } from './providers/Notification.provider'
import { PlatformDetectionProvider } from './providers/PlatformDetection.provider'
import { RouterProvider } from './providers/Router.provider'
import { FinancingSessionProvider } from './providers/FinancingSession.provider'
import { ThemeProvider } from './providers/Theme.provider'
import { TranslationProvider } from './providers/Translation.provider'
import { UrlLanguageProvider } from './providers/UrlLanguage.provider'

// Context
import { EngineProvider } from './context/Engine.context'
import { EnvProvider } from './context/Env.context'
import { DataProvider } from './context/Data.context'
import { LocalDataProvider } from './context/LocalData.context'
import { ModuleLoaderProvider } from './context/ModuleLoader.context'
import { ModuleSystemProvider } from './context/ModuleSystem.context'
import { NavigationProvider } from './context/Navigation.context'
import { PartnerProductProvider } from './context/PartnerProduct.context'

// CSS
import 'react-toastify/dist/ReactToastify.css'
import { AppConfigDataProvider } from './providers/AppConfigData.provider'
import { TrackerContextProvider } from './context/Tracker.context'
import { Provider as ReduxStoreProvider } from 'react-redux'
import { appStore } from '../data/redux/appStore.redux'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
      refetchOnWindowFocus: false,
    },
  },
})

export const App = () => (
  <ReduxStoreProvider store={appStore}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <TrackerContextProvider>
        <BrowserError>
          <ErrorBoundary>
            <Suspense fallback={<Loading label='loading application' />}>
              <AppConfigDataProvider>
                <TrackingProvider>
                  <ModuleLoaderProvider>
                    <FinancingSessionProvider>
                      <RouterProvider>
                        <DataProvider>
                          <PartnerProductProvider>
                            <NavigationProvider>
                              <AcceptCookieProvider />
                              <NotificationProvider />
                              <TranslationProvider />
                              <GoogleTagManagerProvider>
                                <EnvProvider>
                                  <EngineProvider>
                                    <HelmetProvider />
                                    <UrlLanguageProvider>
                                      <ThemeProvider>
                                        <AppVersionProvider>
                                          <LocalDataProvider>
                                            <ModuleSystemProvider>
                                              <PlatformDetectionProvider>
                                                <EmailValidationProvider>
                                                  <ModuleDelegator />
                                                </EmailValidationProvider>
                                              </PlatformDetectionProvider>
                                            </ModuleSystemProvider>
                                          </LocalDataProvider>
                                        </AppVersionProvider>
                                      </ThemeProvider>
                                    </UrlLanguageProvider>
                                  </EngineProvider>
                                </EnvProvider>
                              </GoogleTagManagerProvider>
                            </NavigationProvider>
                          </PartnerProductProvider>
                        </DataProvider>
                      </RouterProvider>
                    </FinancingSessionProvider>
                  </ModuleLoaderProvider>
                </TrackingProvider>
              </AppConfigDataProvider>
            </Suspense>
          </ErrorBoundary>
        </BrowserError>
      </TrackerContextProvider>
    </QueryClientProvider>
  </ReduxStoreProvider>
)
