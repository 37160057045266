import { getFinancingObjectFormLens } from '../../shared/helpers.lenses'
import {
  ButtonType,
  FinancingObjectFormFields,
  FormFieldType,
  FormStateType,
} from '../../types/form.types'
import { Module, ModuleConfig } from '../../types/frontend.types'

const formFields: FinancingObjectFormFields = [
  {
    type: FormFieldType.FormSection,
    fields: [
      {
        label: {
          label: 'formFields.firstName.label',
          sublabel: 'formFields.firstName.sublabel',
        },
        type: FormFieldType.TextInput,
        lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'name']),
        required: true,
      },

      {
        label: {
          label: 'formFields.lastName.label',
          sublabel: 'formFields.lastName.sublabel',
        },
        type: FormFieldType.TextInput,
        lens: getFinancingObjectFormLens(['customerInfo', 'baseInfo', 'surname']),
        required: true,
      },
    ],
  },
]

export const FixName = (): ModuleConfig => ({
  type: Module.Form,
  moduleHeader: {
    title: 'fixName.fixNameTitle',
    description: 'fixName.fixNameDescription',
  },
  formStateType: FormStateType.FinancingObject,
  formProps: {
    onSubmit: ({ patchFinObj, formState }) => {
      patchFinObj({
        isSameModuleSystem: true,
        updates: [
          {
            value: formState.customerInfo.baseInfo.name,
            path: '/customer/baseInfo/name',
            op: 'replace',
          },
          {
            value: formState.customerInfo.baseInfo.surname,
            path: '/customer/baseInfo/surname',
            op: 'replace',
          },
        ],
      })
    },
    formFields,
    buttons: [
      {
        type: ButtonType.Primary,
        label: 'fixName.updateApplicationButtonLabel',
        onClick: ({ submit }) => {
          submit()
        },
      },
    ],
  },
})
