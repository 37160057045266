import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSetModuleSystem } from '../../data/mutations/financingObject.mutations'
import { dummyOnboardingPartnerInfo } from '../../shared/assets/dev/dummyOnboardingPartnerInfo'
import { computeInitialAmount, computeInitialDuration } from '../../shared/helpers'
import {
  FinancingObject,
  OnboardingChannel,
  PreFinancingObject,
  ProductSettingsT,
} from '../../types/financing.types'
import { RequestParams } from '../../types/frontend.types'
import { PartnerInfo } from '../../types/partner.types'
import { usePartnerSessionOnboardingData } from '../providers/PartnerSession.provider'
import { useOptionalFinObj } from './Data.context'
import { useProduct } from './PartnerProduct.context'
import { getPartnerInfoData } from '../../shared/helpers.withEnvVars'

// =============================================================
// Financing object context
// =============================================================

const computeFinObj = (product: ProductSettingsT, obj: FinancingObject): FinancingObject => ({
  ...obj,
  financingInfo: {
    ...obj.financingInfo,
    duration: obj.financingInfo.duration || computeInitialDuration(product, obj.financingInfo.amount),
  },
})

type FinObjDataContextT = {
  financing: FinancingObject
  setFinancing: (v: FinancingObject) => void
}

const LocalFinObjDataContext = createContext<FinObjDataContextT | undefined>(undefined)
LocalFinObjDataContext.displayName = 'Local FinObj Context'

export const useOptionalLocalFinObjData = (): undefined | FinObjDataContextT => {
  const val = useContext(LocalFinObjDataContext)
  return val
}

export const useLocalFinObjData = (): FinObjDataContextT => {
  const val = useContext(LocalFinObjDataContext)
  if (!val) {
    throw new Error(`Local financing object not found.`)
  }
  return val
}

// =============================================================
// Pre-Financing object context
// =============================================================

const initialPreFinObj = (product: ProductSettingsT): PreFinancingObject => ({
  variantId: '',
  amount: product.minTransactionAmount + product.amountOffset,
  duration: product.minNumberOfInstallments + product.durationOffset,
  agentCode: '',
  orderReference: null,
  hasPpi: true,
  isAgePolicy: false,
  isResidencyPolicy: false,
  isOccupancyPolicy: false,
  isDebtsPolicy: false,
  isBobPolicyAccepted: false,
  isPartnerPolicyAccepted: product.onboardingChannel === OnboardingChannel.POSTFINANCE ? false : true,
  isNationalityPolicy: false,
  interestRate: 0,
  financingPartner: 'bob Finance',
})

const computePreFinObj = (params: {
  product: ProductSettingsT
  finObj?: FinancingObject
  preFinObj?: PreFinancingObject
  requestParams?: RequestParams
}): PreFinancingObject => ({
  ...initialPreFinObj(params.product),
  ...(params.preFinObj || {}),
  variantId: params.product.variantId,
  duration:
    params.finObj?.financingInfo.duration ||
    computeInitialDuration(
      params.product,
      params.finObj?.financingInfo.amount,
      params.preFinObj,
      params.requestParams,
    ) ||
    0,
  amount:
    params.finObj?.financingInfo.amount ||
    computeInitialAmount(params.product, params.preFinObj, params.requestParams) ||
    0,
  hasPpi:
    params.finObj?.financingInfo.hasPPI ||
    !(
      params.requestParams?.radioCreditProtection === '0' ||
      params.requestParams?.requestedLoanHasPPI === '0'
    ),
})

type PreFinancingDataContextT = {
  value: PreFinancingObject
  setValue: (v: PreFinancingObject) => void
}

const LocalPreFinObjDataContext = createContext<PreFinancingDataContextT | undefined>(undefined)
LocalPreFinObjDataContext.displayName = 'Local Pre Financing Data Context'

export const useLocalPreFinObjData = (): PreFinancingDataContextT => {
  const val = useContext(LocalPreFinObjDataContext)
  if (!val) {
    throw new Error(`Local pre financing data not found.`)
  }
  return val
}

// =============================================================
// Partner info data context
// =============================================================

export const initialPartnerInfo = (): PartnerInfo => getPartnerInfoData()
  

type PartnerInfoDataContextT = {
  partnerInfo: PartnerInfo
  setPartnerInfo: (v: PartnerInfo) => void
}

const LocalPartnerInfoDataContext = createContext<PartnerInfoDataContextT>({
  partnerInfo: initialPartnerInfo(),
  setPartnerInfo: () => {},
})
LocalPartnerInfoDataContext.displayName = 'Partner Info Data Context'

export const useLocalPartnerInfoData = (): PartnerInfoDataContextT => {
  const val = useContext(LocalPartnerInfoDataContext)
  if (!val) {
    throw new Error(`Local partner info data not found.`)
  }
  return val
}

// =============================================================
// Local data provider
// =============================================================

export const LocalDataProvider = (props: { children: ReactNode }) => {
  const { product } = useProduct()
  const finObj = useOptionalFinObj()
  const [search] = useSearchParams()
  const setModuleSystem = useSetModuleSystem()

  // Financing object
  const [localFinObj, setLocalFinObj] = useState<FinancingObject | undefined>(
    finObj ? computeFinObj(product, finObj) : undefined,
  )

  useEffect(() => {
    setLocalFinObj(finObj ? computeFinObj(product, finObj) : undefined)
  }, [finObj, product])

  const localFinObjData = localFinObj
    ? {
        financing: localFinObj,
        setFinancing: setLocalFinObj,
      }
    : undefined

  // Pre-financing object
  const requestParams: RequestParams = {
    radioCreditProtection: search.get('radioCreditProtection'),
    requestedLoanAmount: search.get('requestedLoanAmount'),
    requestedLoanDuration: search.get('requestedLoanDuration'),
    requestedLoanHasPPI: search.get('requestedLoanHasPPI'),
  }

  const [localPreFinObj, setPreLocalFinObj] = useState<PreFinancingObject | undefined>(
    computePreFinObj({ product, finObj, requestParams }),
  )

  useEffect(() => {
    setPreLocalFinObj(
      computePreFinObj({
        product,
        finObj,
        preFinObj: localPreFinObj,
        requestParams,
      }),
    )
  }, [finObj])

  const localPreFinObjData = localPreFinObj
    ? { value: localPreFinObj, setValue: setPreLocalFinObj }
    : undefined

  // Partner info
  const { partnerOnboardingData } = usePartnerSessionOnboardingData()
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>(initialPartnerInfo())
  const partnerInfoData = { partnerInfo, setPartnerInfo }
  const isPartnerOnboarding = search.get('isPartnerOnboarding') === '1'

  useEffect(() => {
    const { partnerInfo, moduleSystem } = partnerOnboardingData.get()

    if (partnerInfo && moduleSystem) {
      setPartnerInfo(partnerInfo)
      setModuleSystem(moduleSystem)
    }
  }, [isPartnerOnboarding])

  return (
    <LocalFinObjDataContext.Provider value={localFinObjData}>
      <LocalPreFinObjDataContext.Provider value={localPreFinObjData}>
        <LocalPartnerInfoDataContext.Provider value={partnerInfoData}>
          {props.children}
        </LocalPartnerInfoDataContext.Provider>
      </LocalPreFinObjDataContext.Provider>
    </LocalFinObjDataContext.Provider>
  )
}
