import merge from 'lodash.merge'
import { computeRetailMonthlyRate } from '../../shared/helpers'
import {
  EmploymentRelationship,
  FinancingAddress,
  FinancingObject,
  FinancingObjectBE,
  OnboardingChannelVariant,
} from '../../types/financing.types'

const emptyAddress = {
  addressLastModifiedDate: null,
  addressSince: null,
  addressSource: null,
  addressType: null,
  addrModified: false,
  city: null,
  country: null,
  houseNr: null,
  region: null,
  street: null,
  zip: null,
}

const mapAddress = (address: FinancingAddress) => ({
  ...address,
  houseNr: address.houseNr === '-' ? null : address.houseNr,
})

export const processInboundFinancingObj = (finObjBE: FinancingObjectBE): FinancingObject => ({
  ...finObjBE,
  customerInfo: {
    ...finObjBE.customerInfo,
    previousAddress:
      finObjBE.customerInfo.addresses.length === 2
        ? mapAddress(finObjBE.customerInfo.addresses[1])
        : emptyAddress,
    currentAddress:
      finObjBE.customerInfo.addresses.length > 0
        ? mapAddress(finObjBE.customerInfo.addresses[0])
        : emptyAddress,
  },
  computedMonthlyRate: computeRetailMonthlyRate(finObjBE),

  financingInfo: {
    ...finObjBE.financingInfo,
    amount:
      finObjBE.financingInfo.amount > 0 || finObjBE.financingInfo.duration > 0
        ? finObjBE.financingInfo.amount
        : null,
    duration:
      finObjBE.financingInfo.amount > 0 || finObjBE.financingInfo.duration > 0
        ? finObjBE.financingInfo.duration
        : null,
  },
  otpVerificationInfo: {
    ...finObjBE.otpVerificationInfo,
    contract: {
      ...finObjBE.otpVerificationInfo.contract,
      otpValue: finObjBE.errorInfo.error.isError ? null : finObjBE.otpVerificationInfo.contract.otpValue,
    },
    mobile: {
      ...finObjBE.otpVerificationInfo.mobile,
      otpValue: finObjBE.errorInfo.error.isError ? null : finObjBE.otpVerificationInfo.mobile.otpValue,
    },
  },
  fileObj: {
    fileUpload: null,
    fileUpload2: null,
    fileUpload3: null,
    fileUpload4: null,
    fileUpload5: null,
  },

  stateLevelInfo: {
    ...finObjBE.stateLevelInfo,
    isShortTerm: [OnboardingChannelVariant.SINGLETERM, OnboardingChannelVariant.SHORTTERM].includes(
      finObjBE.contextInfo.onboardingChannelVariant,
    ),
  },
  errorInfo: {
    ...finObjBE.errorInfo,
    error: {
      ...finObjBE.errorInfo.error,
      isRejection: finObjBE.errorInfo.error.isRejectionDueToLimitExceeded
        ? false
        : finObjBE.errorInfo.error.isRejection,
    },
  },
})

export type OptIns =
  | {
      privacyPolicyAndContractTermsAgreed: true
      proceedToCustomerDataFinalization: true
    }
  | {
      privacyPolicyAccepted: true
      proceedToCustomerDataFinalization: boolean
    }

export const processOutboundCustomerInfo = (params: {
  financing: FinancingObject
  optIns: OptIns
}): FinancingObjectBE['customerInfo'] => {
  const customerInfo = params.financing.customerInfo
  const mappedCustomerInfo = merge({}, customerInfo)

  // Base Info
  mappedCustomerInfo.baseInfo.countryCode = customerInfo.baseInfo.countryCode || '+41'

  // Addresses
  mappedCustomerInfo.addresses = [
    ...(customerInfo.currentAddress.city
      ? [
          {
            ...customerInfo.currentAddress,
            country: 'CHE',
          },
        ]
      : []),
    ...(customerInfo.previousAddress.city ? [customerInfo.previousAddress] : []),
  ]

  // Detail Info
  const employment = customerInfo.applicantDetailInfo.employment
  const family = customerInfo.applicantDetailInfo.family

  mappedCustomerInfo.applicantDetailInfo.employment = {
    ...employment,
    employmentRelationship: EmploymentRelationship.Employed, // default this
  }
  mappedCustomerInfo.applicantDetailInfo.family = {
    ...family,
    yearOfBirthOfChildren:
      family.numberOfChildren !== null
        ? (family.yearOfBirthOfChildren || []).slice(0, family.numberOfChildren || 0)
        : [],
  }

  // Financing Info
  const debts = customerInfo.applicantFinancialInfo.debts
  mappedCustomerInfo.applicantFinancialInfo.debts = {
    ...debts,
    doRedeemDebts:
      debts.hasPendingLeases !== null && debts.hasPendingDebts !== null
        ? (debts.hasPendingLeases || debts.hasPendingDebts) && debts.doRedeemDebts
        : null,
    numberOfDebtsToRedeem: debts.doRedeemDebts ? debts.numberOfDebtsToRedeem : null,
  }

  const income = customerInfo.applicantFinancialInfo.income
  mappedCustomerInfo.applicantFinancialInfo.income = {
    ...income,
    hasSecondIncome:
      income.hasSecondIncome &&
      !!(income.secondIncome || income.additionalHouseholdIncome || income.annualBonus),
  }

  const partnerIncome = customerInfo.applicantFinancialInfo.partnerIncome
  mappedCustomerInfo.applicantFinancialInfo.partnerIncome = {
    ...partnerIncome,
    hasSecondIncome:
      partnerIncome.hasSecondIncome &&
      !!(
        partnerIncome.secondIncome ||
        partnerIncome.additionalHouseholdIncome ||
        partnerIncome.annualBonus
      ),
  }

  // OptIns
  mappedCustomerInfo.optIns = {
    ...customerInfo.optIns,
    isSelfDeclarationDone: true,
    subscribeToBobMarketing: !!customerInfo.optIns.subscribeToBobMarketing,
    ...params.optIns,
  }

  return mappedCustomerInfo
}

export const mapErrorInfo = (
  financing: FinancingObjectBE | FinancingObject,
): FinancingObject['errorInfo'] => {
  const errorInfo = {
    ...financing.errorInfo,
    hasSmsSendoutFailed: financing.errorInfo.warning.hasSmsSendoutFailed,
    isWarningSMSLinkExpired:
      !financing.errorInfo.error.isError &&
      !financing.errorInfo.error.isRejection &&
      financing.errorInfo.warning.hasSmsLinkExpiredForPartner,
  }

  return errorInfo
}

type StatusList = FinancingObject['stateList']

export const filterStateLevel = (stateLevelList: StatusList, doShowToCustomer: boolean): StatusList => {
  return stateLevelList.reduce((acc: StatusList, level: StatusList[0]) => {
    if (doShowToCustomer && !level.levelInfo.doShowToCustomer) {
      return acc
    } else {
      return acc.concat({
        ...level,
        childLevels: filterStateLevel(level.childLevels || [], doShowToCustomer),
      })
    }
  }, [] as StatusList)
}
