import { createContext, ReactNode, useContext, useState } from 'react'
import { Tracker } from '../../types/analytics.types'

type TrackerContextT = {
  tracker: Tracker
  setTracker: (value: Tracker) => void
}

const initialTracker: Tracker = {
  hasAppInsightsTracking: false,
  isInitialized: false,
  operationId: () => null,
  addPartner: () => null,
  trackPageView: () => null,
  trackEvent: () => null,
  trackException: () => null,
  trackMissingTranslation: () => null,
}

const TrackerContext = createContext<TrackerContextT>({
  tracker: initialTracker,
  setTracker: () => {},
})
TrackerContext.displayName = 'TrackerContext'

export const useTracker = (): TrackerContextT => {
  return useContext(TrackerContext)
}

export const TrackerContextProvider = (props: { children: ReactNode }) => {
  const [tracker, setTracker] = useState<Tracker>(initialTracker)

  return (
    <TrackerContext.Provider value={{ tracker, setTracker }}>
      {props.children}
    </TrackerContext.Provider>
  )
}
